.style_logo__QwQu6 {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: var(--logoBackground);
  border-radius: 3.75rem;
  overflow: hidden;
}
.style_logo__QwQu6 img {
  height: auto;
  width: auto;
  padding: 0.25rem;
}
.style_logo__QwQu6 .style_disabled__1IJd3 {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  /* filter doesn't work for IE11, known issue, we fallback to opacity only in this case */
  opacity: 0.4;
}
