.style_phase-icon__vPn10 {
  box-sizing: border-box;
  background: var(--secondaryText);
  width: 1rem;
  height: 1rem;
  display: inline-flex;
  vertical-align: top;
  margin-right: 0.5rem;
  align-items: center;
  justify-content: center;
  color: var(--whiteConstant);
  border-radius: 0.125rem;
}
.style_phase-icon-before__ExhIC {
  font-family: FontAwesome;
  font-size: 0.625rem;
  position: relative;
}
.style_phase-icon__vPn10.style_optional__1ngqo {
  background: linear-gradient(90deg, var(--divider) 50%, var(--whiteConstant) 0);
  border: solid 0.0625rem var(--divider);
}
.style_phase-icon__vPn10.style_blocked__2SWPV {
  background: var(--primaryBackground) !important;
  border: solid 0.0625rem var(--primaryText);
}
.style_phase-icon__vPn10.style_blocked__2SWPV::after {
  content: '';
  width: 0.625rem;
  height: 0.625rem;
  background: var(--dangerConstant);
  position: relative;
  display: inline-block;
}
.style_phase-icon__vPn10.style_blocked__2SWPV::before {
  display: none;
}
.style_phase-icon__vPn10.style_current__3sGnY {
  background: var(--mainBackground);
  border: none;
}
.style_phase-icon__vPn10.style_current__3sGnY::before {
  content: '\f061';
  padding-bottom: 0.125rem;
  font-family: FontAwesome;
  font-size: 0.625rem;
  position: relative;
}
.style_phase-icon__vPn10.style_complete__22rZk {
  background: var(--successConstant);
}
.style_phase-icon__vPn10.style_complete__22rZk::before {
  content: '\f00c';
  font-family: FontAwesome;
  font-size: 0.625rem;
  position: relative;
}
.style_phase-icon__vPn10.style_deployed__38-ew {
  background: var(--successConstant);
}
.style_phase-icon__vPn10.style_deployed__38-ew::before {
  content: '\f00c';
  font-family: FontAwesome;
  font-size: 0.625rem;
  position: relative;
}
.style_phase-icon__vPn10.style_automatic__EjZx7 {
  background: var(--alertConstant);
}
.style_phase-icon__vPn10.style_automatic__EjZx7::before {
  content: '\f0e7';
  padding-left: 0.125rem;
  font-family: FontAwesome;
  font-size: 0.625rem;
  position: relative;
}
.style_phase-icon__vPn10.style_queued__1gFUT {
  background: var(--infoConstant);
}
.style_phase-icon__vPn10.style_queued__1gFUT::before {
  content: '\f017';
  font-family: FontAwesome;
  font-size: 0.625rem;
  position: relative;
}
.style_phase-icon__vPn10.style_warning__3Dw0B {
  background: var(--successConstant);
}
.style_phase-icon__vPn10.style_warning__3Dw0B::before {
  content: '\f071';
  font-family: FontAwesome;
  font-size: 0.625rem;
  position: relative;
}
.style_phase-icon__vPn10.style_skipped__acWGU {
  width: 1rem;
  height: 1rem;
  background: var(--divider);
  border: dashed 0.125rem var(--ternaryText);
}
