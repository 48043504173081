.style_taskDetailsCell__2pl3c {
  vertical-align: "middle";
}
.style_stats__2ZwRp {
  padding-left: 1rem;
}
.style_states__3vy0J {
  width: 16rem;
}
.style_checkboxFiltersContainer__3ynN9 {
  margin-bottom: 1rem;
}
