.style_container__30snF {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  min-width: 11.25rem;
  padding: 0.5rem;
}
.style_container__30snF .style_additionalDetails__1pNfZ {
  margin-left: 0.5rem;
}
.style_task-previous__10Xz3 {
  opacity: 0.65;
}
.style_task-archived__3KWVV {
  opacity: 0.2;
}
.style_details__3H_th {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 0.5rem;
  width: 100%;
}
.style_version__1t2Yb,
.style_taskName__1awr8 {
  color: var(--primaryText);
  font-weight: 500;
  font-size: 0.9375rem;
  display: flex;
  align-items: center;
  width: 100%;
}
.style_date__28Gw5 {
  color: var(--ternaryText);
  font-weight: 400;
  font-size: 0.6875rem;
}
.style_taskLink__23iVd:hover {
  background-color: var(--hover);
}
