@media (max-width: 37.4rem) {
  .CustomDialog_hide-sm__7K8DW {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .CustomDialog_visible-sm__38TAm {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .CustomDialog_hide-md__eJ6tG {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .CustomDialog_visible-md__1SL90 {
    display: none !important;
  }
}
.CustomDialog_overlay__2eRXN {
  background-color: var(--primaryText);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
}
.CustomDialog_focusArea__2GJjW {
  height: 100%;
  width: 100%;
}
