.style_materialUIIcon__1sk0x {
  padding: 0 !important;
  justify-content: center;
  align-content: center;
  display: flex!important;
  width: auto !important;
  height: auto !important;
  opacity: 0.7;
}
.style_materialUIIcon__1sk0x span {
  padding: 0rem !important;
}
.style_materialUIIcon__1sk0x svg {
  fill: var(--iconNeutral) !important;
  color: var(--iconNeutral) !important;
}
.style_materialUIIcon__1sk0x .style_cancelSmall__3y0aF {
  height: 1.25rem !important;
  width: 1.25rem !important;
}
.style_materialUIIcon__1sk0x:hover {
  background-color: transparent !important;
}
.style_materialUIIcon__1sk0x:hover {
  opacity: 1;
}
.style_imageIcon__264Ki {
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0.7;
}
.style_imageIcon__264Ki:hover {
  opacity: 1;
}
.style_fontAwesomeIcon__wQsIJ {
  color: var(--iconNeutral);
  opacity: 0.7;
}
.style_fontAwesomeIcon__wQsIJ:hover {
  opacity: 1;
}
