.style_error__77cPb {
  padding: 1.5rem 1rem 1.5rem 1rem;
  color: var(--dangerText);
  background-color: var(--dangerBackground);
  text-align: left;
}
.style_error__77cPb ul {
  padding-left: 1rem;
  margin-bottom: 0;
  list-style-type: disc;
  font-weight: 400;
}
.style_error__77cPb .style_note__1fSJr {
  margin-top: 1rem;
}
.style_titleContainer__jWNxz {
  display: flex;
  justify-content: space-between;
}
.style_title__30UcC {
  font-weight: 500;
}
.style_detailLink__1eMfq {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
