.style_deletedContent__1dKIX {
  color: var(--secondaryText) !important;
  text-decoration: line-through;
}
.style_nameCell__z7W85 {
  height: 100%;
  overflow: "hidden";
  font-weight: 600;
}
.style_nameCellContentReadonly__poIt7 {
  height: 100%;
  display: flex;
  align-items: center;
}
.style_inputContainer__1W7T7 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
