@media (max-width: 37.4rem) {
  .style_hide-sm__30aFD {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__3buc1 {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__1tmzq {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__3-OPs {
    display: none !important;
  }
}
.style_healthStatusCardTitleContainer__5FPo8 {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  padding: 0;
  margin-left: 1.5rem;
}
@media (max-width: 37.4rem) {
  .style_healthStatusCardTitleContainer__5FPo8 {
    margin-left: 0.75rem;
  }
}
.style_healthStatusCardTitleContainer__5FPo8 .style_healthStatusIconContainer__37Lbu {
  display: flex;
  min-width: 1rem;
  padding-right: 0.4rem;
}
.style_healthStatusCardTitleContainer__5FPo8 .style_healthStatusName__3Ztzy {
  color: var(--primaryText);
  font-weight: 400;
  margin: 0.25rem 0;
  padding-right: 0.4rem;
  text-transform: uppercase;
}
.style_healthStatusCardTitleContainer__5FPo8 .style_healthStatusMachinesCount__2ZasP {
  color: var(--secondaryText);
  margin: 0.25rem 0;
  padding-right: 0.4rem;
}
.style_healthStatusCardTitleContainer__5FPo8 .style_healthStatusIcon__3UBbf {
  margin-right: 0.25rem;
  background: var(--logoBackground);
  border-radius: 1.875rem;
  border: 0.125rem solid var(--logoBackground);
}
