@media (max-width: 37.4rem) {
  .style_hide-sm__2rgTy {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__1-C0M {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__3Xk-v {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__7O5AA {
    display: none !important;
  }
}
.style_layout__1fdLq {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.style_headerContentAlignment__12spC {
  padding-left: 1rem;
  padding-right: 1rem;
}
.style_title__20cNr {
  font-size: 1.063rem;
  font-weight: 500;
  padding: 0.75rem 1rem;
}
.style_titleIcon__3RlbS {
  margin-right: 0.5rem;
  display: inline-block;
}
.style_body__17-c0 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  padding: 0.5rem 1rem 1rem !important;
}
.style_actions__1fUpN {
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  flex-wrap: wrap;
}
.style_materialDialogBody__1G_Wr {
  padding: 0;
}
.style_primaryActions__3Eerb > * {
  margin-left: 0.5rem;
}
.style_secondaryActions__2KRAG > * {
  margin-right: 0.5rem;
}
.style_dialogLayout__p61Lt {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}
.style_dialogLayout__p61Lt .style_dialogLayoutKeyboardHandler__LurNI {
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media (max-width: 37.4rem) {
  .style_dialogLayout__p61Lt {
    display: block;
  }
}
.style_dialogWrapper__2RgtW {
  height: 100%;
}
.style_headerContainer__OQG4b {
  padding: 0!important;
}
