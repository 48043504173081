@media (max-width: 37.4rem) {
  .style_hide-sm__DmR3f {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__2LwxZ {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__1yGiB {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__1RxOt {
    display: none !important;
  }
}
.style_tagSetContainer__doZ-M {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.style_connectButtonContainer__10Q6E {
  width: 100%;
  text-align: center;
}
.style_linkedProject__25tKd {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 500;
  flex-grow: 1;
}
.style_linkedProject__25tKd .style_header__1_v0o {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.style_linkedProject__25tKd .style_header__1_v0o .style_projectName__nm4UI {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.style_linkedProject__25tKd .style_header__1_v0o .style_projectName__nm4UI a {
  margin-left: 0.25rem;
  font-size: 1.063rem;
  font-weight: 500;
}
.style_details__3OBP2 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.style_noEnvironmentsWarning__2ynHL {
  color: var(--alert);
  padding-top: 0.5rem;
}
.style_noEnvironmentsWarningIcon__D5bk3 {
  padding-right: 0.5rem;
}
.style_noProjectAccess__bKq2F {
  margin-top: 1rem;
}
