@media (max-width: 37.4rem) {
  .style_hide-sm__1OOBQ {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__2cnZS {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__2pPoi {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__66A-Z {
    display: none !important;
  }
}
.style_regardingAnyContainer__3-NcR {
  padding: 0.5rem;
  border: 0.0625rem solid var(--secondaryBackground);
  border-radius: 0.125rem;
}
.style_dateRangeFilterContainer__Uo7AI {
  width: 16.5rem;
}
.style_filterHeaderContainer__2DJor {
  align-items: flex-start;
}
.style_noResultsContainer__zEEy_ {
  width: 20rem;
  margin: 0 auto;
  text-align: center;
}
.style_dateWrapper__2I_zP {
  font-weight: bold;
  white-space: nowrap;
}
.style_noWrap__3u5Zr {
  white-space: nowrap;
}
