@media (max-width: 37.4rem) {
  .styles_hide-sm__zGJFU {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .styles_visible-sm__1ANv_ {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .styles_hide-md__1C216 {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .styles_visible-md__2iz54 {
    display: none !important;
  }
}
.styles_templates__paBym {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.styles_templates__paBym li {
  display: flex;
}
.styles_categoryTemplates__2c__m > div,
.styles_categories__2K4o1 > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.styles_categoryTemplates__2c__m li {
  display: flex;
  height: 100%;
}
@media (max-width: 37.4rem) {
  .styles_templates__paBym,
  .styles_categoryTemplates__2c__m > div {
    flex-direction: column;
  }
}
.styles_categoryName__10M-V {
  color: var(--mainText);
  text-align: center;
  margin-top: 0.8125rem;
  margin-bottom: 0.8125rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 5.9375rem;
}
.styles_actions__2E7gQ {
  text-align: right;
  margin-right: 1rem;
}
.styles_onHoverActions__2bjH0 {
  background-color: var(--cardHover);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
}
.styles_onHoverActions__2bjH0 .styles_heading__1Z_dQ,
.styles_onHoverActions__2bjH0 .styles_logo__2Od8r,
.styles_onHoverActions__2bjH0 .styles_content__bWJiZ {
  opacity: 0.7;
}
.styles_onHoverPrimaryActions__NTWHk {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
}
.styles_onHoverPrimaryActionsCursor__1O_fd {
  cursor: pointer;
}
.styles_primaryAction__1SaRD {
  flex: 4;
}
.styles_secondaryAction__3-2A0 {
  background-color: var(--paper2);
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  width: 100%;
  text-align: center;
}
.styles_empty__2wEc_ {
  flex: 3;
}
.styles_templateListTitle__2JqwX {
  background-color: var(--hover);
  border-top: dashed var(--divider) 0.0625rem;
  border-bottom: dashed var(--divider) 0.0625rem;
  padding: 0.5rem 1.125rem;
  margin-right: 1rem;
}
