.ProjectedTeamsList_root__19kXO {
  flex: 0 0 0;
  display: flex;
  align-items: center;
}
.ProjectedTeamsList_detail__lSCD3 {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  padding-left: 1rem;
}
.ProjectedTeamsList_icon__2qN91 {
  width: 32px;
  height: 32px;
  fill: var(--primaryText) !important;
}
.ProjectedTeamsList_externalGroups__1o_J2 {
  color: var(--primaryText);
}
