.style_taskSummaryTopLevel__2GZ7B {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.style_taskSummaryChildren__sC7pC {
  margin-left: 1rem;
}
.style_taskSummaryLine__3Ve-D {
  display: flex;
  margin: 0.5rem 0;
  color: var(--primaryText);
}
.style_taskSummaryLineText__1YsX4 {
  cursor: pointer;
}
.style_summaryIcon__2ARCC {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
  text-align: center;
}
.style_artifactsBlock__1jZIz {
  margin-left: 1.25rem;
}
.style_highlightBlock__IcHux {
  color: var(--infoText);
  margin-left: 1.25rem;
}
.style_fatalBlock__KBuiM {
  color: var(--dangerText);
  margin-left: 1.25rem;
}
