@media (max-width: 37.4rem) {
  .notifications_hide-sm__1Dqsv {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .notifications_visible-sm__1zG_q {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .notifications_hide-md__1t5qg {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .notifications_visible-md__2nz1g {
    display: none !important;
  }
}
.notifications_container__3gW75 {
  display: flex;
  flex-direction: column;
  width: 18.75rem;
}
@media (max-width: 37.4rem) {
  .notifications_container__3gW75 {
    width: 16.25rem;
  }
}
.notifications_grow__WQLnf {
  flex-grow: 1;
  padding-right: 0.625rem;
}
.notifications_notification__2Nzs4 {
  padding: 0.4375rem 0.75rem;
  display: flex;
}
.notifications_title__lw14u {
  font-size: 0.875rem;
  font-weight: 600;
  padding-bottom: 0.0625rem;
}
.notifications_description__v1c3X {
  font-size: 0.8125rem;
  color: var(--ternaryText);
}
.notifications_icon__2SHPW {
  display: flex;
  align-items: center;
}
.notifications_links__3isfH {
  margin-top: 0.625rem;
  font-size: 0.8125rem;
}
.notifications_notificationIcon__CQNvr {
  width: 1.625rem;
  height: 1.625rem;
  line-height: 1.625rem !important;
  text-align: center;
  border-radius: 0.125rem;
  font-size: 0.875rem;
  color: var(--whiteConstant);
}
.notifications_textHappy__12KU2 {
  color: var(--infoConstant);
}
.notifications_bgHappy__34Ru3 {
  background-color: var(--primaryLight);
}
.notifications_textInfo__VH_u- {
  color: var(--primaryLight);
}
.notifications_bgInfo__1H10N {
  background-color: var(--primaryLight);
}
.notifications_textWarn__p8UJc {
  color: var(--alertConstant);
}
.notifications_bgWarn__3Un-H {
  background-color: var(--alertConstant);
}
.notifications_textError__3cVx4 {
  color: var(--dangerConstant);
}
.notifications_bgError__3IEm0 {
  background-color: var(--dangerConstant);
}
