@media (max-width: 37.4rem) {
  .style_hide-sm__2NlTC {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__33vMs {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__2TqqG {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__3Ybk4 {
    display: none !important;
  }
}
.style_buttonDialogContainer__3AJj7 {
  display: inline-block;
}
.style_materialDialogContent__NyhSI {
  padding: 0;
  max-width: 800px !important;
}
@media (max-width: 37.4rem) {
  .style_materialDialogContent__NyhSI {
    width: 90% !important;
  }
}
.style_warningIcon__Rxts1 {
  color: var(--alert);
}
.style_dialogHeader__2bJ2k {
  padding: 0!important;
}
.style_confirmationDialogHeader__2Kh0j {
  padding: 0!important;
  background: var(--alertHighlight);
}
