@media (max-width: 37.4rem) {
  .DeploymentProcessEditor_hide-sm__ZgETC {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .DeploymentProcessEditor_visible-sm__3IAtF {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .DeploymentProcessEditor_hide-md__1q9wF {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .DeploymentProcessEditor_visible-md__xZfqW {
    display: none !important;
  }
}
.DeploymentProcessEditor_container__3wfLZ {
  display: flex;
  flex-direction: row;
}
@media (max-width: 37.4rem) {
  .DeploymentProcessEditor_container__3wfLZ {
    flex-direction: column;
  }
}
.DeploymentProcessEditor_container__3wfLZ .DeploymentProcessEditor_sidebar__3O5Qo {
  width: 20rem;
  border-right: 0.0625rem solid var(--divider);
}
@media (max-width: 37.4rem) {
  .DeploymentProcessEditor_container__3wfLZ .DeploymentProcessEditor_sidebar__3O5Qo {
    width: 100%;
    border-right: unset;
    border-bottom: 0.0625rem solid var(--divider);
  }
}
.DeploymentProcessEditor_container__3wfLZ .DeploymentProcessEditor_content__1LQUN {
  width: 100%;
}
.DeploymentProcessEditor_headerBody__Aoctc {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 1rem;
}
.DeploymentProcessEditor_headerBodyFilter__3wFXg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.DeploymentProcessEditor_regardingAnyContainer__JXe8p {
  margin-right: 1rem;
  padding: 0.5rem;
  border: 0.0625rem solid var(--secondaryBackground);
  border-radius: 0.125rem;
}
