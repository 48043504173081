.style_sortableItem__uXsLf {
  border: 0.0625rem solid var(--divider);
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  background-color: var(--hover);
  cursor: move;
  color: var(--primaryText);
}
.style_name__1lT_b {
  padding-left: 1rem;
}
