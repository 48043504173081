@media (max-width: 37.4rem) {
  .style_hide-sm__2Y5yH {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__16XQP {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__2LynC {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__J67NT {
    display: none !important;
  }
}
.style_container__jZnOY {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.style_select__2n_bA {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
}
.style_summary__3hr6A {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 1 100%;
}
.style_row__27jHf {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
.style_row__27jHf:nth-last-child(1) {
  margin-top: 0.5rem;
}
.style_titleContainer__1tudS {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.style_title__1GXxX {
  width: 100%;
}
.style_details__3pVW7 {
  display: flex;
  flex-direction: row;
}
.style_icon__MWcE4 {
  padding: 0 0 0 0.4rem;
  align-self: center;
}
.style_propertyContainer__1jmMx {
  padding: 0.4rem 0.4rem 0 0;
  display: flex;
  color: var(--primaryText);
  font-size: 0.875rem;
}
.style_propertyContainer__1jmMx span:nth-child(2) {
  margin-left: 0.25rem;
  margin-right: 0.75rem;
}
.style_propertyContainer__1jmMx + .style_thumbprint__2_KYg {
  font-weight: 400;
}
