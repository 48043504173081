@media (max-width: 37.4rem) {
  .style_hide-sm__3YREX {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__9s6MK {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__Ln-Xr {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__ClSim {
    display: none !important;
  }
}
.style_cardsContainer__1hG7h {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
  margin: 1rem 0 1.6rem 0;
}
.style_dashboardFilterInputClassName__boAiw {
  font-size: 0.8125rem !important;
  width: 15rem !important;
}
.style_dashboardFilterInputClassName__boAiw .style_searchIcon__1gpMH {
  position: absolute;
  right: 0;
  top: 12px;
  width: 20px !important;
  height: 20px !important;
}
.style_card__23Hmw {
  margin: 1rem;
  width: 17.13rem;
  height: 21.875rem;
  min-height: 21.875rem;
  box-shadow: var(--shadow20) 0rem 0.1875rem 0.0625rem -0.125rem, var(--shadow14) 0rem 0.125rem 0.125rem 0rem, var(--shadow12) 0rem 0.0625rem 0.3125rem 0rem;
  border-radius: 0.25rem;
  overflow: hidden;
  color: var(--primaryText);
  text-decoration: none;
  background: var(--paper2);
  transition: background-color 0.1s ease-in;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  position: relative;
}
.style_card__23Hmw .style_cardTitleContainer__iX3Xs {
  color: var(--whiteConstant);
  background-color: var(--mainBackground);
  font-size: 1.063rem;
  height: 3rem;
  min-height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}
.style_card__23Hmw .style_cardTitleContainer__iX3Xs .style_cardCount__2Nc7S {
  font-size: 0.875rem;
  font-weight: 300;
}
.style_card__23Hmw .style_cardContentContainer__23DI_ {
  color: var(--secondaryText);
  font-size: 0.8125rem;
  padding: 1rem;
  overflow-y: auto;
}
.style_card__23Hmw:after {
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 281px;
  content: "";
  background: linear-gradient(to top, var(--paper2), transparent);
  pointer-events: none;
  left: 0;
  z-index: 1;
  overflow: hidden;
}
.style_environmentRowsContainer__3qsYv {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
}
.style_environmentRowsContainer__3qsYv .style_environmentIcon__2ptaN {
  display: flex;
  min-width: 1.5rem;
  padding-right: 0.4rem;
}
.style_environmentRowsContainer__3qsYv .style_environmentIcon__2ptaN svg {
  width: 18px !important;
  height: 16px !important;
}
.style_environmentRowsContainer__3qsYv .style_environmentName__2fdRc {
  margin: 0.25rem 0;
  padding-right: 0.4rem;
}
.style_environmentRowsContainer__3qsYv .style_environmentMachinesCount__6jLdl {
  margin: 0.25rem 0;
  margin-left: auto;
}
.style_workerpoolRowsContainer__25SsD {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
}
.style_workerpoolRowsContainer__25SsD .style_workerpoolIcon__3M-z8 {
  display: flex;
  min-width: 1rem;
  padding-right: 0.4rem;
}
.style_workerpoolRowsContainer__25SsD .style_workerpoolIcon__3M-z8 svg {
  width: 18px !important;
  height: 16px !important;
}
.style_workerpoolRowsContainer__25SsD .style_workerpoolName__2TFkL {
  margin: 0.25rem 0;
  padding-right: 0.4rem;
}
.style_workerpoolRowsContainer__25SsD .style_workerpoolMachinesCount__1eVHv {
  margin: 0.25rem 0;
  margin-left: auto;
}
.style_endpointRowsContainer__2w88G {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  margin-bottom: 0.4rem;
}
.style_endpointRowsContainer__2w88G .style_endpointIcon__3TgYQ {
  display: flex;
  min-width: 1rem;
  margin-right: 0.4rem;
  background-color: var(--whiteConstant);
  border-radius: 1.875rem;
  padding: 0.15rem;
}
.style_endpointRowsContainer__2w88G .style_endpointName__3xKqm {
  margin: 0.25rem 0;
  padding-right: 0.4rem;
}
.style_endpointRowsContainer__2w88G .style_endpointMachinesCount__3tBnL {
  margin: 0.25rem 0;
  margin-left: auto;
}
.style_healthStatusRowsContainer__3BySB {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  margin-bottom: 0.4rem;
}
.style_healthStatusRowsContainer__3BySB .style_healthStatusIcon__1PaBv {
  background: var(--logoBackground);
  border-radius: 30px;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
  border: 0.125rem solid var(--logoBackground);
}
.style_healthStatusRowsContainer__3BySB .style_healthStatusIcon__1PaBv img {
  display: flex;
  min-width: 1rem;
}
.style_healthStatusRowsContainer__3BySB .style_healthStatusName__1PYaE {
  margin: 0.25rem 0;
  padding-right: 0.4rem;
}
.style_healthStatusRowsContainer__3BySB .style_healthStatusMachinesCount__qTGMt {
  margin: 0.25rem 0;
  margin-left: auto;
}
.style_tenantRowsContainer__26kfs {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  margin-bottom: 0;
}
.style_tenantRowsContainer__26kfs .style_tenantName__3XBZr {
  padding-right: 0.4rem;
}
.style_tenantRowsContainer__26kfs .style_tenantMachinesCount__f5bI1 {
  margin: 0.25rem 0;
  margin-left: auto;
}
.style_tenantTagRowsOuterContainer__2kR_q {
  margin-bottom: 1rem;
}
.style_tenantTagRowsOuterContainer__2kR_q h5 {
  color: var(--primaryText);
  margin: 0.25rem 0;
  padding: 0;
}
.style_tenantTagRowsContainer__2xrfz {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  margin-bottom: 0;
}
.style_tenantTagRowsContainer__2xrfz .style_tenantTagName__2Ert8 {
  padding-right: 0.4rem;
}
.style_tenantTagRowsContainer__2xrfz .style_tenantTagMachinesCount__3V00q {
  margin: 0.25rem 0;
  margin-left: auto;
}
