@media (max-width: 37.4rem) {
  .style_hide-sm__24yfG {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__C6gME {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__2pX9e {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__2XInG {
    display: none !important;
  }
}
.style_cardList__1H7yq {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
  margin: 1rem 0 1.6rem 0;
}
.style_cardList__1H7yq > a {
  display: flex;
}
.style_filterHeaderContainer__1MJ7_ {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex: 1 0 auto;
  width: 100%;
}
@media (max-width: 37.4rem) {
  .style_filterHeaderContainer__1MJ7_ {
    flex-direction: column;
    align-items: flex-start;
  }
}
.style_filterFieldContainer__2T8iP {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex: 1 0 auto;
  width: auto;
  justify-content: flex-start;
}
@media (max-width: 37.4rem) {
  .style_filterFieldContainer__2T8iP {
    flex-direction: column;
    align-items: flex-start;
  }
}
.style_filter__3DRlN {
  align-items: flex-end;
  flex: 1 0 auto;
  max-width: 50rem;
}
.style_filterField__28wID {
  width: 100%;
}
.style_filterField__28wID > div {
  width: 100%;
}
@media (max-width: 37.4rem) {
  .style_cardList__1H7yq {
    flex-direction: column;
  }
  .style_filterFieldContainer__2T8iP,
  .style_filter__3DRlN {
    flex-direction: column;
    width: 100%;
    margin: 0 0.5rem;
  }
}
.style_actionContainer__3PEcM {
  display: flex;
  align-items: center;
}
.style_groupHeader__IKXGq {
  display: flex;
  align-items: center;
  background: var(--groupHeaderBackground);
  color: var(--groupHeader);
  border-radius: 0.25rem;
  overflow: hidden;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  font-size: 1.0625rem;
  justify-content: space-between;
}
.style_groupDescription__2yHdh {
  padding: 0 0.9375rem;
}
.style_lifecycleMap__1hkGV {
  margin-top: 1rem;
}
.style_help__3pFnn {
  margin-bottom: 12px;
}
.style_info__2Ba6P {
  color: var(--secondaryText);
}
.style_loadMoreContainer__3dXEY {
  padding: 1rem;
}
.style_loadMoreActions__3pN7p {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
}
.style_loadMoreSubText__2rTJW {
  font-size: 0.75rem;
  color: var(--secondaryText);
}
.style_loadMoreHeaderInfo__EvvuO {
  padding: 0.5rem 1rem;
  color: var(--info);
}
