@media (max-width: 37.4rem) {
  .style_hide-sm__2_UNZ {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__3c-b_ {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__VyVeN {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__cDGlw {
    display: none !important;
  }
}
.style_paperLayoutOverride__358Di > div:first-child {
  box-shadow: unset;
  margin: 0;
  border-radius: unset;
}
.style_cardTitleContainer__3rSbF {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  padding: 0 1rem;
  color: var(--primaryText);
  line-height: 2.25;
}
.style_cardTitleContainer__3rSbF .style_environmentIcon__1ssmf {
  display: flex;
  padding-right: 0.4rem;
  width: 2rem;
  min-width: 1.5rem;
  height: auto;
}
.style_cardTitleContainer__3rSbF .style_environmentName__2yP5W {
  font-weight: 500;
  margin: 0.25rem 0;
  padding-right: 0.4rem;
  white-space: nowrap;
  font-size: 1rem;
}
.style_cardTitleContainer__3rSbF .style_environmentMachinesCount__PpFVm {
  color: var(--secondaryText);
  margin: 0.25rem 0;
  padding-right: 0.4rem;
}
.style_cardTitleContainer__3rSbF .style_environmentSummaryCounts__24Jnj {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: 3rem;
}
@media (max-width: 37.4rem) {
  .style_cardTitleContainer__3rSbF .style_environmentSummaryCounts__24Jnj {
    display: none;
  }
}
.style_cardTitleContainer__3rSbF .style_environmentSummaryCounts__24Jnj .style_summaryCount__3Y7Lg {
  margin: 0.25rem 0;
  padding-right: 0.4rem;
  display: flex;
  align-items: center;
  font-size: 0.8125rem !important;
  text-transform: uppercase;
}
.style_cardTitleContainer__3rSbF .style_environmentOverflowActions__OB-P6 {
  position: absolute;
  z-index: 1;
  right: 1rem;
  margin: auto;
  width: 3rem;
  height: 2.25rem;
}
.style_machineIcon__3wiJS {
  width: 2.438rem;
  height: 2.563rem;
}
.style_healthStatusIcon__1M2dd {
  margin-right: 0.25rem;
  vertical-align: middle;
  border: 0.125rem solid var(--logoBackground);
  background: var(--logoBackground);
  border-radius: 1.875rem;
}
.style_formExpander__16FLt,
.style_formNonExpander__3pPLY {
  width: 100%;
  box-shadow: none!important;
  border-radius: 0!important;
  border-width: 0!important;
  margin: 0 0 -0.0625rem 0;
  background-color: inherit !important;
}
.style_formExpander__16FLt > div:first-child,
.style_formNonExpander__3pPLY > div:first-child {
  padding: 0!important;
}
.style_cardMedia__3EgxC {
  margin: 0;
  padding: 0;
}
.style_healthStatusCardTitleContainer__2-LVG {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  padding: 0;
  margin-left: 1.5rem;
}
@media (max-width: 37.4rem) {
  .style_healthStatusCardTitleContainer__2-LVG {
    margin-left: 0.75rem;
  }
}
.style_healthStatusCardTitleContainer__2-LVG .style_healthStatusIconContainer__RzWtM {
  display: flex;
  min-width: 1rem;
  padding-right: 0.4rem;
}
.style_healthStatusCardTitleContainer__2-LVG .style_healthStatusName__sZMGo {
  color: var(--primaryText);
  font-weight: 400;
  margin: 0.25rem 0;
  padding-right: 0.4rem;
  text-transform: uppercase;
}
.style_healthStatusCardTitleContainer__2-LVG .style_healthStatusMachinesCount__1MS6l {
  color: var(--secondaryText);
  margin: 0.25rem 0;
  padding-right: 0.4rem;
}
