.style_busyIndicator__1AAa7 {
  width: 100%;
}
.style_busyIndicator__1AAa7 div:first-child {
  background-color: var(--divider);
}
.style_busyIndicatorHiddenSpacer__2UW0Q {
  width: 100%;
  height: 0.25rem;
}
.style_circularBusyIndicator__1Syhs div:first-child {
  vertical-align: middle;
  background-color: transparent;
}
