.styles_imageContainer__2fAAK {
  display: flex;
}
.styles_svgIcon__5Zj4V {
  display: flex;
  height: 100% !important;
  width: 100% !important;
}
.styles_svgIcon__5Zj4V .styles_imgCyan__21xtA {
  fill: var(--imgCyan);
}
.styles_svgIcon__5Zj4V .styles_imgCyan50__29HGf {
  fill: var(--imgCyan50);
}
.styles_svgIcon__5Zj4V .styles_imgWhite__2s5fO {
  fill: var(--imgWhite);
}
.styles_svgIcon__5Zj4V .styles_imgNavy__3ubWn {
  fill: var(--imgNavy);
}
.styles_svgIcon__5Zj4V .styles_imgDark__BnQkI {
  fill: var(--imgDark);
}
.styles_svgIcon__5Zj4V .styles_imgLight__1pnqj {
  fill: var(--imgLight);
}
.styles_svgIcon__5Zj4V .styles_imgBlueGrey__f6pk1 {
  fill: var(--imgBlueGrey);
}
.styles_svgIcon__5Zj4V .styles_imgGrey__3arWh {
  fill: var(--imgGrey);
}
.styles_svgIcon__5Zj4V .styles_imgGreen__26v4b {
  fill: var(--imgGreen);
}
.styles_svgIcon__5Zj4V .styles_dashDark___WG4O {
  stroke: var(--imgDark);
  fill: transparent;
  animation: styles_dash__1PxE6 60s linear infinite;
  -webkit-animation: styles_dash__1PxE6 60s linear infinite;
  stroke-dasharray: 5;
}
@keyframes styles_dash__1PxE6 {
  to {
    stroke-dashoffset: 1000;
  }
}
