.tippy-popper .tippy-tooltip {
  background-color: var(--tooltipBackground) !important;
  color: var(--tooltipText) !important;
}
.tippy-popper[x-placement^="right"] .arrow-regular {
  border-right-color: var(--tooltipBackground) !important;
}
.tippy-popper[x-placement^="left"] .arrow-regular {
  border-left-color: var(--tooltipBackground) !important;
}
.tippy-popper[x-placement^="top"] .arrow-regular {
  border-top-color: var(--tooltipBackground) !important;
}
.tippy-popper[x-placement^="bottom"] .arrow-regular {
  border-bottom-color: var(--tooltipBackground) !important;
}
.style_tooltipContent__3-w62 p {
  color: var(--tooltipText);
  padding: 0.25rem 0;
  margin: 0;
}
