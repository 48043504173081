.DrawerWrapperLayout_container__1GYUt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.DrawerWrapperLayout_header__1ds3K {
  padding: 0.75rem 1rem;
  background: var(--shadow60);
  color: var(--whiteConstant);
  font-size: 1rem;
}
.DrawerWrapperLayout_content__37mIe {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
}
.DrawerWrapperLayout_footer__1Jqnc {
  padding: 0.75rem 1rem;
  background: var(--mainBackground);
  color: var(--whiteConstant);
}
.DrawerWrapperLayout_footer__1Jqnc h4 {
  margin: 0.25rem 0;
  color: var(--whiteConstant);
}
.DrawerWrapperLayout_footer__1Jqnc a,
.DrawerWrapperLayout_footer__1Jqnc span {
  font-size: 0.875rem;
  color: var(--whiteConstant);
}
.DrawerWrapperLayout_footer__1Jqnc a:hover {
  color: var(--whiteConstant);
}
#DrawerWrapperLayout_drawerwrapper__fKf1h h4 {
  font-size: 0.875rem;
  font-weight: 500;
  margin: 1rem 0 0.15rem 0;
}
.DrawerWrapperLayout_drawerPaper__1zcJN {
  background-color: var(--paper1);
}
