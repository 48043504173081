@media (max-width: 37.4rem) {
  .text_hide-sm__1jwxz {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .text_visible-sm__2xcNR {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .text_hide-md__2o5Kb {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .text_visible-md__2oncX {
    display: none !important;
  }
}
h1 {
  font-size: 1.625rem;
  font-weight: 400;
  color: var(--primaryText);
}
@media (max-width: 37.4rem) {
  h1 {
    font-size: 1.4375rem;
  }
}
h2 {
  font-size: 1.25rem;
  font-weight: 400;
  color: var(--primaryText);
}
@media (max-width: 37.4rem) {
  h2 {
    font-size: 1.0625rem;
  }
}
h3 {
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--primaryText);
}
@media (max-width: 37.4rem) {
  h3 {
    font-size: 1.0625rem;
  }
}
h4 {
  font-size: 1rem;
  font-weight: 500;
  margin: 1rem 0 0.5rem 0;
  color: var(--primaryText);
}
h5 {
  font-size: 0.8125rem;
  font-weight: 400;
  margin: 1rem 0 0.5rem 0;
  color: var(--primaryText);
}
hr {
  display: block;
  height: 0.0625rem;
  border: 0;
  border-top: solid 0.0625rem var(--divider);
  margin: 1em 0;
  padding: 0;
}
a {
  color: var(--linkText);
}
a:hover {
  color: var(--linkTextHover);
}
p {
  margin: 0.75rem 0;
  color: var(--primaryText);
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px var(--whiteConstant) inset !important;
}
input::-ms-clear {
  width: 0;
  height: 0;
}
small {
  font-size: 0.875rem;
  line-height: 1.1875rem;
}
.text_secondary__15tvR {
  color: var(--secondaryText);
}
