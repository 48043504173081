.style_preWrapped__2Y688 {
  position: relative;
  float: left;
  width: 100%;
  padding: 0.5rem;
  margin: 0 0 0.5rem;
  font-size: 9pt;
  word-break: break-all;
  word-wrap: break-word;
  background-color: var(--paper0);
  border: 0.0625rem dashed var(--divider);
  border-radius: 0.125rem;
}
.style_preWrapped__2Y688 div > ins {
  border-bottom: 0.125rem solid var(--successText) !important;
  padding: 0.1rem 0.375rem;
  text-decoration: unset!important;
}
.style_preWrapped__2Y688 div > del {
  border-bottom: 0.125rem solid var(--danger) !important;
  padding: 0.1rem 0.375rem;
}
.style_changeIndicatorOverlay__Ml2GH {
  margin: 0.5rem 0;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.style_changeLine__1NtzK {
  position: absolute;
  min-height: 1.3125rem;
  width: 100%;
  background-color: var(--auditHighlight);
  border-left: 0.125rem solid var(--divider);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.style_changeSet__2capC {
  position: relative;
  width: 100%;
}
