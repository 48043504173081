.styles_codeEditorContainer__kcdLr {
  position: relative;
  min-height: 15rem;
  border: 0.0625rem solid var(--divider);
}
.styles_codeEditorContainerFullScreen__2FmpK {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: auto;
  height: auto;
  z-index: 999;
  background: var(--paper0);
}
.styles_enterFullScreen__3wGci {
  position: relative;
  text-align: right;
  float: right;
  padding-right: 1.25rem;
  z-index: 9;
}
.styles_exitFullScreen__19wf7 {
  text-align: right;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;
  padding: 0.5rem;
}
.CodeMirror-foldmarker {
  color: var(--primaryText);
  text-shadow: var(--secondaryText) 1px 1px 2px, var(--secondaryText) -1px -1px 2px, var(--secondaryText) 1px -1px 2px, var(--secondaryText) -1px 1px 2px;
  font-family: arial;
  line-height: 0.3;
  cursor: pointer;
}
.CodeMirror-foldgutter {
  width: 0.7em;
}
.CodeMirror-foldgutter-open, .CodeMirror-foldgutter-folded {
  cursor: pointer;
}
.CodeMirror-foldgutter-open:after {
  content: "\25BE";
}
.CodeMirror-foldgutter-folded:after {
  content: "\25B8";
}
.CodeMirror-gutters {
  background-color: var(--paper1) !important;
}
.CodeMirror-linenumber {
  color: var(--secondaryText) !important;
}
.CodeMirror {
  height: inherit;
  font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
  font-size: 1rem;
  background: var(--paper0) !important;
  color: var(--primaryText) !important;
}
.cm-s-default .cm-comment {
  color: var(--dangerText) !important;
}
.cm-s-default .cm-builtin {
  color: var(--infoText) !important;
}
.cm-s-default .cm-number {
  color: var(--successText) !important;
}
.cm-s-default .cm-attribute {
  color: var(--infoText) !important;
}
.cm-s-default .cm-def {
  color: var(--infoText) !important;
}
.cm-s-default .cm-string {
  color: var(--dangerText) !important;
}
.cm-s-default .cm-error {
  color: var(--dangerConstant) !important;
}
.cm-s-default .cm-keyword {
  color: var(--codeText) !important;
}
