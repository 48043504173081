.style_box__2cnCq {
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: var(--primaryText);
}
.style_information__3Yh9G {
  background-color: var(--infoBackground);
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: var(--primaryText);
}
.style_information__3Yh9G .style_title__2KHFV {
  color: var(--infoText);
}
.style_success__m2qEC {
  background-color: var(--successBackground);
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: var(--primaryText);
}
.style_success__m2qEC .style_title__2KHFV {
  color: var(--successText);
}
.style_warning__k92Ea {
  background-color: var(--alertBackground);
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: var(--primaryText);
}
.style_warning__k92Ea .style_title__2KHFV {
  color: var(--alertText);
}
.style_danger__vx8Ek {
  background-color: var(--dangerBackground);
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: var(--primaryText);
}
.style_danger__vx8Ek .style_title__2KHFV {
  color: var(--dangerText);
}
.style_newFeature__D2U3N {
  background: var(--featureBackground);
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: var(--primaryText);
}
.style_newFeature__D2U3N .style_title__2KHFV {
  color: var(--featureText);
}
.style_none__3uvNB {
  padding: 0.625rem 1rem;
  align-self: stretch;
  font-size: 0.875rem;
  color: var(--primaryText);
}
.style_title__2KHFV + div {
  margin-top: 0.25rem;
}
.style_title__2KHFV > .style_note__3rjlO {
  margin-bottom: 0;
}
