.style_container__2m4Z_ {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.style_container__2m4Z_ > * {
  margin: 1.5rem 0 0 0;
}
.style_content__3Bsrj {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--paper1);
  width: 23.5rem;
  text-align: center;
  border-radius: 0.25rem;
  overflow: hidden;
  box-shadow: var(--shadow20) 0rem 0.1875rem 0.1875rem -0.125rem, var(--shadow14) 0rem 0.1875rem 0.25rem 0rem, var(--shadow12) 0rem 0.0625rem 0.5rem 0rem;
}
.style_description__76Jo0 {
  padding: 0 1.5rem 1.5rem 1.5rem;
  width: 100%;
}
.style_back__3L6j3 {
  padding-top: 1.5rem;
}
.style_logo__3kZ7x {
  background-color: var(--cyanConstant);
  color: var(--whiteConstant);
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}
