.style_description__W3wNf {
  font-size: 0.875rem;
  color: var(--primaryText);
}
.style_information__2x0Xv {
  font-size: 1rem;
  margin: 0.9375rem;
  line-height: 1.5rem;
}
.style_sidebar__1auzp {
  font-size: 0.875rem;
}
.style_callouts__3Tq0H li {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  margin-bottom: 1.5rem;
}
.style_disabled__yEGoL * {
  color: var(--secondaryText);
}
.style_disabled__yEGoL * svg {
  opacity: 0.5;
}
.style_markdownContainer__3_I6p {
  padding: 1rem 1rem;
}
