@media (max-width: 37.4rem) {
  .style_hide-sm__3HPqN {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__zfNET {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__1U6nA {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__3vqnq {
    display: none !important;
  }
}
.style_dataTable__3ZfPz {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1rem;
}
.style_dataTableHeader__2v3r5 .style_dataTableRow__3zwOH {
  border-top: none;
}
.style_dataTableHeader__2v3r5 .style_dataTableRow__3zwOH:hover,
.style_dataTableHeader__2v3r5 .style_dataTableRow__3zwOH:focus {
  background-color: transparent;
}
.style_dataTableHeaderColumn__1AAtu {
  color: var(--secondaryText);
  padding: 0 1.125rem 0.75rem 1.125rem;
  line-height: 1rem;
  font-weight: 400;
  padding-bottom: 1rem;
  padding-top: 1rem;
  box-sizing: border-box;
}
.style_dataTableHeaderColumn__1AAtu {
  text-align: left;
}
.style_dataTableRowHeaderColumn__2gCbd {
  vertical-align: top;
  padding-top: 0.875rem;
  text-align: right;
  width: 16rem;
}
.style_dataTableRowHeaderColumnInSidebar__1zH_w {
  width: 8rem !important;
}
.style_dataTableFooterColumn__3DEBQ {
  color: var(--secondaryText);
  padding: 0;
  line-height: 1rem;
  font-weight: 400;
  padding-bottom: 1rem;
  text-align: left;
  box-sizing: border-box;
}
.style_dataTableRow__3zwOH {
  border-top: 0.0625rem solid var(--divider);
}
.style_dataTableRow__3zwOH:last-child {
  border-bottom: 0.0625rem solid var(--divider);
}
.style_dataTableRow__3zwOH.style_noBorder__3E3gi {
  border: none;
}
.style_dataTableRow__3zwOH.style_noBorder__3E3gi:hover,
.style_dataTableRow__3zwOH.style_noBorder__3E3gi:focus {
  background-color: transparent;
}
.style_dataTableRowColumn__33JV3 {
  box-sizing: border-box;
  color: var(--primaryText);
  vertical-align: top;
  padding: 0.75rem 1.125rem;
}
.style_dataTableRowColumn__33JV3:first-child {
  font-weight: 500;
}
.style_dataTableRowColumn__33JV3 a {
  text-align: left;
  color: var(--mainText);
}
@media (max-width: 37.4rem) {
  .style_dataTable__3ZfPz,
  .style_dataTableHeader__2v3r5,
  .style_dataTableBody__3jFvB,
  .style_dataTableHeaderColumn__1AAtu,
  .style_dataTableFooterColumn__3DEBQ,
  .style_dataTableRow__3zwOH,
  .style_dataTableRowColumn__33JV3 {
    display: block;
  }
  .style_dataTable__3ZfPz {
    border: none;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  .style_dataTableHeader__2v3r5 .style_dataTableRow__3zwOH:not(.style_dataTableToolsRow__2lvQ-) {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .style_dataTableRowColumn__33JV3 {
    border: none;
    position: relative;
    line-height: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0;
    max-width: 100%;
  }
  .style_dataTableRowHeaderColumn__2gCbd {
    padding: 0.75rem 1.125rem;
    text-align: left;
    width: unset;
  }
  .style_dataTableRowHeaderColumnInSidebar__1zH_w {
    width: unset;
  }
  .style_dataTableRowColumn__33JV3:first-child,
  .style_dataTableRowColumn__33JV3:not(:first-child) {
    width: 100%;
  }
  .style_dataTableRowColumn__33JV3:not(:first-child) {
    padding-top: 0;
  }
}
