.style_valueCell__QL3FW {
  height: 100%;
}
.style_deletedText__2oFsZ {
  color: var(--secondaryText) !important;
  text-decoration: line-through;
}
.style_deletedSensitive__Wax7j {
  color: var(--secondaryText) !important;
}
.style_valueCellContentReadonly__Gdh6_ {
  height: 100%;
  display: flex;
  align-items: center;
}
.style_promptedVariablePositionContainer__1AE2_ {
  padding-right: 1rem;
}
.style_promptedVariableIconSizeContainer__1E7Q1 {
  height: 1.5rem;
  width: 1.5rem;
}
.style_promptedVariableIcon__cHaO3 {
  opacity: 0.5;
  color: var(--secondaryText);
}
.style_value__1ihyW {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.style_iconContainer__2D4fC {
  height: inherit;
  display: flex;
  align-items: center;
  padding-right: 0.5rem;
}
.style_certificate__15XvW,
.style_account__1ZB48 {
  height: inherit;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.style_certificate__15XvW:focus,
.style_account__1ZB48:focus {
  outline: none;
}
.style_certificateLink__Er0Bw,
.style_accountLink__2dmA_ {
  height: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--primaryDark);
}
.style_handCursor__3BenA {
  cursor: pointer;
}
.style_multilineText__1YyGQ {
  padding-top: 14px;
  max-height: 35px;
  padding-bottom: 10px;
  overflow: hidden !important;
}
.style_multilineTextFocused__3nefn {
  padding-top: 14px !important;
  max-height: 300px !important;
}
.style_multilineContainer__1hraX {
  white-space: pre;
  width: 100%;
  height: 100%;
}
