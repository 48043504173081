@media (max-width: 37.4rem) {
  .style_hide-sm__2yZr7 {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__1tL9i {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__1RcKV {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__1Lpo- {
    display: none !important;
  }
}
h1 {
  font-size: 1.625rem;
  font-weight: 400;
  color: var(--primaryText);
}
@media (max-width: 37.4rem) {
  h1 {
    font-size: 1.4375rem;
  }
}
h2 {
  font-size: 1.25rem;
  font-weight: 400;
  color: var(--primaryText);
}
@media (max-width: 37.4rem) {
  h2 {
    font-size: 1.0625rem;
  }
}
h3 {
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--primaryText);
}
@media (max-width: 37.4rem) {
  h3 {
    font-size: 1.0625rem;
  }
}
h4 {
  font-size: 1rem;
  font-weight: 500;
  margin: 1rem 0 0.5rem 0;
  color: var(--primaryText);
}
h5 {
  font-size: 0.8125rem;
  font-weight: 400;
  margin: 1rem 0 0.5rem 0;
  color: var(--primaryText);
}
hr {
  display: block;
  height: 0.0625rem;
  border: 0;
  border-top: solid 0.0625rem var(--divider);
  margin: 1em 0;
  padding: 0;
}
a {
  color: var(--linkText);
}
a:hover {
  color: var(--linkTextHover);
}
p {
  margin: 0.75rem 0;
  color: var(--primaryText);
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px var(--whiteConstant) inset !important;
}
input::-ms-clear {
  width: 0;
  height: 0;
}
small {
  font-size: 0.875rem;
  line-height: 1.1875rem;
}
.style_secondary__2Yf9w {
  color: var(--secondaryText);
}
.style_daysField__mqwFb {
  margin-left: 4rem;
  max-width: 4rem;
  margin-top: -1rem;
}
.style_packageVersionCell__2wZee {
  vertical-align: middle !important;
}
.style_packageVersionCell__2wZee:nth-child(2) {
  font-weight: 500;
}
.style_row__1nXU5 {
  display: flex;
}
.style_cardSubTitle__1MnEN {
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
  color: var(--primaryText);
  margin-right: 2rem;
  display: block;
  word-wrap: break-word;
  min-width: 50%;
}
@media (max-width: 37.4rem) {
  .style_cardSubTitle__1MnEN {
    margin-left: 0rem !important;
  }
}
.style_cardSubTitle__1MnEN > div > p {
  margin: 0;
}
.style_box__3tbx- {
  padding: 0.25rem;
  align-self: stretch;
  font-weight: 400;
  margin-right: 0.5rem;
}
.style_taskSucceeded__2SUPp {
  background-color: var(--successHighlight);
  padding: 0.25rem;
  align-self: stretch;
  font-weight: 400;
  margin-right: 0.5rem;
  color: var(--successText);
}
.style_taskSucceeded__2SUPp .style_title__1aRVC {
  color: var(--successText);
}
.style_taskFailed__3zYmG {
  background-color: var(--dangerHighlight);
  padding: 0.25rem;
  align-self: stretch;
  font-weight: 400;
  margin-right: 0.5rem;
  color: var(--dangerText);
}
.style_taskFailed__3zYmG .style_title__1aRVC {
  color: var(--dangerText);
}
.style_descriptionColumn__2HNKw {
  min-width: 20rem;
}
.style_actionButtonGroup__247bZ {
  margin-bottom: 1rem;
}
.style_center__Vd8YG {
  text-align: center;
}
.style_packagingHelp__3_03l {
  margin: 0 auto 2rem auto;
  text-align: center;
}
.style_packagingHelpTwo__1Oi5G {
  width: 70%;
  margin: 0 auto 2rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.style_packagingHelpTwo__1Oi5G > section {
  width: 50%;
  padding: 1rem;
  box-shadow: var(--shadow20) 0rem 0.1875rem 0.1875rem -0.125rem, var(--shadow14) 0rem 0.1875rem 0.25rem 0rem, var(--shadow12) 0rem 0.0625rem 0.5rem 0rem;
}
.style_learnMoreLinks__1g8Rr {
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
