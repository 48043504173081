@media (max-width: 37.4rem) {
  .SectionStepLink_hide-sm__2Sh98 {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .SectionStepLink_visible-sm__xM3Sq {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .SectionStepLink_hide-md__2m176 {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .SectionStepLink_visible-md__R2Zv- {
    display: none !important;
  }
}
.SectionStepLink_lookLikeALink__2YNfJ {
  color: var(--mainText);
  cursor: pointer;
}
.SectionStepLink_lookLikeALink__2YNfJ:hover {
  color: var(--primaryDark);
}
