@media (max-width: 37.4rem) {
  .style_hide-sm__1LawO {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__39qs4 {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__izE11 {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__22gKT {
    display: none !important;
  }
}
.style_lists__2nCNR {
  display: flex;
}
@media (max-width: 37.4rem) {
  .style_lists__2nCNR {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
}
.style_list__2iEdV {
  flex: 50%;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
}
.style_listHeader__1TkmU {
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
}
.style_listHeader__1TkmU h4 {
  margin: 0.5rem 0;
}
.style_templateItem__3Jzti {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.style_templateItemName__37jCI {
  flex: 2;
  margin-left: 1rem;
  margin-right: 1rem;
}
.style_importNote__2WOv2,
.style_exportNote__3u1zE {
  margin-top: 1rem;
}
.style_warning__1KXtg {
  margin: 1rem 0;
}
.style_headerColumn__1xqdF:first-child {
  width: 20%;
}
.style_licence__5TrBb {
  text-align: right;
  margin-right: 1rem;
}
.style_summary__VEq6h {
  display: flex;
  align-items: center;
}
.style_logo__2363C {
  margin-right: 1rem;
}
.style_divider__2XraV {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.style_icon__3FZIK {
  padding-left: 0.5rem;
}
.style_mergeTabs__mAsm- {
  display: flex;
  flex-direction: row;
}
.style_mergeTabs__mAsm- .style_navTabs__1ZUMn {
  background-color: var(--alertHighlight);
  color: var(--alert);
  background-size: 20px 20px;
  border-radius: 2px;
  padding: 10px;
}
.style_mergeTabs__mAsm- .style_green__2Kggs {
  background-color: var(--successHighlight);
  color: var(--successText);
  background-size: 20px 20px;
}
.style_mergeTabs__mAsm- .style_active__h-9nR .style_showDetails__1hpKL {
  visibility: hidden;
}
.style_mergeTabs__mAsm- .style_active__h-9nR .style_navTabs__1ZUMn {
  background-color: var(--alertBackground);
  color: var(--whiteConstant);
}
.style_mergeTabs__mAsm- .style_active__h-9nR .style_navTabs__1ZUMn a {
  color: var(--whiteConstant);
}
.style_mergeTabs__mAsm- .style_active__h-9nR .style_green__2Kggs {
  background-color: var(--successBackground);
  color: var(--whiteConstant);
}
.style_mergeTabs__mAsm- .style_active__h-9nR .style_green__2Kggs a {
  color: var(--whiteConstant);
}
.style_mergeTabs__mAsm- > * {
  margin: 0.5rem;
  flex-grow: 1;
}
.style_mergeTabs__mAsm- > *:first-child {
  margin-left: 0;
}
.style_mergeTabs__mAsm- > *:last-child {
  margin-right: 0;
}
.style_reasons__Ofy8k {
  margin: 0;
}
.style_filterSearchBoxContainer__3DPMJ {
  width: 320px;
}
