.style_scrollUpButton__3S5Av {
  width: 30px;
  height: 30px;
  background-color: var(--ternaryText) !important;
  padding: 1.5rem;
  z-index: 100;
  border-radius: 30px;
  font-family: FontAwesome;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--whiteConstant);
  opacity: 0.7;
  transition: opacity 0.5s;
}
.style_ScrollUpButton__Container__oH0hx {
  position: fixed;
  right: -100px;
  bottom: 20px;
  transition: right 0.5s;
  cursor: pointer;
  font-size: 20px;
  width: 30px;
  height: 30px;
  background-color: var(--ternaryText) !important;
  padding: 1.5rem;
  z-index: 100;
  border-radius: 30px;
  font-family: FontAwesome;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--whiteConstant);
  opacity: 0.7;
  transition: opacity 0.5s;
}
.style_ScrollUpButton__Container__oH0hx:hover {
  opacity: 1;
}
.style_ScrollUpButton__Toggled__24k8M {
  right: 20px;
}
