@media (max-width: 37.4rem) {
  .style_hide-sm__1J4sG {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__2rGXg {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__2uLEg {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__24PUX {
    display: none !important;
  }
}
.style_container__1YhfF {
  text-align: center;
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.style_content__eYZ6U {
  box-shadow: var(--shadow20) 0rem 0.1875rem 0.1875rem -0.125rem, var(--shadow14) 0rem 0.1875rem 0.25rem 0rem, var(--shadow12) 0rem 0.0625rem 0.5rem 0rem;
  background-color: var(--whiteConstant);
  border-radius: 0.25rem;
  overflow: hidden;
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.style_content__eYZ6U h4 {
  color: var(--whiteConstant);
}
.style_content__eYZ6U form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0 1.5rem 0;
}
.style_inProgressMessage__1SQp3 {
  text-align: center;
}
.style_loading__2sM5t {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.style_loading__2sM5t img {
  width: 18.75rem;
  height: 6.976875rem;
  margin-top: 2rem;
}
.style_errorInfoPanel__3Ogn7 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.style_errorInfoPanelDetails__34bmA {
  box-shadow: var(--shadow20) 0rem 0.1875rem 0.1875rem -0.125rem, var(--shadow14) 0rem 0.1875rem 0.25rem 0rem, var(--shadow12) 0rem 0.0625rem 0.5rem 0rem;
  width: 100%;
  text-align: left;
  background-color: var(--hover);
  padding: 0.5rem;
}
