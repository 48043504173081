@media (max-width: 37.4rem) {
  .style_hide-sm__1Ji1R {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__3aBEt {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__2gto4 {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__3yPz3 {
    display: none !important;
  }
}
.style_container__XP1Iv {
  margin: 0.75rem 1rem 0 1rem;
}
