@media (max-width: 37.4rem) {
  .style_hide-sm__3hTC4 {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__3rLoB {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__2TSzF {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__ScPYX {
    display: none !important;
  }
}
.style_section__37fct {
  margin-top: 1rem;
}
.style_results__29ECs .style_box__2Nl3M {
  padding: 0.625rem;
  align-self: stretch;
}
.style_results__29ECs .style_title__AKdz- {
  margin-bottom: 0.625rem;
}
.style_results__29ECs .style_success__1R4w6 {
  background-color: var(--successHighlight);
  padding: 0.625rem;
  align-self: stretch;
}
.style_results__29ECs .style_success__1R4w6 .style_title__AKdz- {
  color: var(--successText);
}
.style_results__29ECs .style_warning__19zNN {
  background-color: var(--dangerHighlight);
  padding: 0.625rem;
  align-self: stretch;
}
.style_results__29ECs .style_warning__19zNN .style_title__AKdz- {
  color: var(--dangerText);
}
.style_results__29ECs .style_none__1pxmu {
  padding: 0.625rem;
  align-self: stretch;
}
.style_stepPreview__vB5Bq .style_step__1_4kh {
  padding-bottom: 0.5rem;
}
.style_stepPreview__vB5Bq .style_skipStepPreviewTitle__2oHWr {
  text-decoration: line-through;
}
.style_stepPreview__vB5Bq .style_actionSummary__3pL6A {
  display: flex;
  flex-flow: row;
  padding-top: 0.875rem;
}
.style_stepPreview__vB5Bq .style_logo__emvEu {
  order: 1;
  display: flex;
  align-items: center;
}
.style_stepPreview__vB5Bq .style_logo__emvEu span {
  margin-left: 0.3rem;
}
.style_stepPreview__vB5Bq .style_machines__1Ekt- {
  padding-left: 1rem;
}
.style_stepPreview__vB5Bq .style_content__2pUTh {
  flex: 4 0;
  order: 2;
  margin-left: 0.5rem;
}
.style_stepToggle__1twHL .style_logo__emvEu {
  order: 1;
  display: flex;
  align-items: center;
}
.style_stepToggle__1twHL .style_logo__emvEu span {
  margin-left: 0.3rem;
}
.style_stepToggle__1twHL .style_note__3Xxfi {
  margin-bottom: 2rem;
}
.style_stepToggle__1twHL .style_stepCheckboxes__KffGX {
  margin-top: -1rem;
}
.style_filter__2ZbD0 {
  max-width: 16rem;
}
.style_excludedAction__2vEsu {
  text-decoration: line-through;
}
.style_excludedActionPostfixLabel__1Emxu {
  color: var(--secondaryText);
}
