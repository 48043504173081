.CustomDialogTitleBar_titleBar__1bIif {
  background-color: var(--whiteConstant);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  flex: 0 0 auto;
  padding: 1rem;
}
.CustomDialogTitleBar_title__27ve7 {
  flex-grow: 1;
  font-size: 1.063rem;
  font-weight: 500;
}
.CustomDialogTitleBar_title__27ve7 h1,
.CustomDialogTitleBar_title__27ve7 h2 {
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
}
.CustomDialogTitleBar_actions__oz4hG {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  flex: 0 0 auto;
  flex-wrap: wrap;
}
.CustomDialogTitleBar_actions__oz4hG > * {
  margin-left: 0.5rem;
}
