@media (max-width: 37.4rem) {
  .style_hide-sm__2ig6F {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__KwLQP {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__3LP0L {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__32qoS {
    display: none !important;
  }
}
.style_components__YCODK {
  margin-top: 0;
}
.style_components__YCODK li {
  margin-top: 0.5rem;
}
.style_entry__14zqW {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.style_entry__14zqW ul.style_bulletList__NAb2X {
  margin-left: 3rem;
}
.style_entry__14zqW ul.style_bulletList__NAb2X li {
  list-style: disc;
}
.style_entry__14zqW .style_content__gXRDf {
  color: var(--primaryText);
  font-size: 0.875rem;
}
.style_entry__14zqW .style_styleGuideExample__3VISp {
  margin-bottom: 0.5rem;
}
.style_container__1SDjW {
  margin: 1rem 2rem;
  display: flex;
  flex-direction: row;
}
.style_container__1SDjW .style_componentMenu__2bB58 {
  min-width: 18.75rem;
}
.style_container__1SDjW > ul {
  list-style: none;
}
.style_container__1SDjW > ul > li {
  margin-bottom: 5px;
}
@media (max-width: 37.4rem) {
  .style_container__1SDjW {
    flex-direction: column;
  }
}
.style_heading__1VKzd {
  text-align: left;
}
.style_content__gXRDf {
  margin: 2rem 1rem;
}
.style_context__2CU84 {
  display: block;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: var(--secondaryBackground);
  font-size: 0.875rem;
  color: var(--ternaryText);
}
.style_context__2CU84 p {
  font-size: 0.875rem;
  color: var(--ternaryText);
}
thead {
  font-weight: 600;
}
.style_colors__3992a {
  display: flex;
  flex-direction: column;
}
.style_colors__3992a .style_colorSwatch__3OlZc {
  flex-direction: row;
  display: flex;
  align-items: center;
  border-top: 0.0625rem solid var(--divider);
  height: 2.5rem;
}
.style_colors__3992a .style_colorSwatch__3OlZc:last-child {
  border-bottom: 0.0625rem solid var(--divider);
}
.style_colors__3992a .style_colorSwatch__3OlZc .style_colorName__3twUx {
  font-weight: 500;
  margin: 0 1rem 0 1rem;
  width: 30%;
}
.style_colors__3992a .style_colorSwatch__3OlZc .style_colorValue__1zXDu {
  color: var(--secondaryText);
}
.style_colors__3992a .style_colorSwatch__3OlZc .style_colorValue__1zXDu:before {
  content: "HEX";
  background-color: var(--secondaryBackground);
  margin-right: 0.25rem;
  font-size: 0.75rem;
  padding: 0.25rem;
}
.style_swatch__1jbu5 {
  width: 3rem;
  height: 2.5rem;
}
.style_white__2-m00 {
  background: var(--whiteConstant);
}
.style_primaryText__22NFE {
  background: var(--primaryText);
}
.style_secondaryText__3PSey {
  background: var(--secondaryText);
}
.style_primary__1QtFq {
  background: var(--mainBackground);
}
.style_primaryLight__3W9rY {
  background: var(--primaryLight);
}
.style_primaryDark__1T-SF {
  background: var(--primaryDark);
}
.style_primaryBackgroundDark__1aKxV {
  background: var(--primaryBackground);
}
.style_readonlyTextWithStrikethrough__1wQf9 > input {
  text-decoration: line-through;
  color: var(--secondaryText) !important;
}
.style_sidebarWithColor__361k5 {
  background-color: grey;
}
.style_row__rEsb3 {
  display: flex;
  flex-direction: row;
}
.style_buttons__1r9hu tr td {
  padding: 0.5rem 0;
}
.style_card__2cyda {
  padding-bottom: 0.5rem;
}
.style_cardTitle__bF6f_ {
  font-weight: 500;
  padding: 1rem;
}
