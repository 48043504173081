@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Regular.3e1af3ef546b9e6ecef9f3ba197bf7d2.hashedasset.ttf);
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Italic.42bbe4eefcde1297b11dc4b6491e9746.hashedasset.ttf);
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Light.fc84e998bc29b297ea20321e4c90b6ed.hashedasset.ttf);
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Medium.d08840599e05db7345652d3d417574a9.hashedasset.ttf);
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Bold.ee7b96fa85d8fdb8c126409326ac2d2b.hashedasset.ttf);
  font-weight: 600;
}
@font-face {
  font-family: 'fontoctopus';
  src: url(fontoctopus.af93096ee4f17f3ce6d6363747b45e97.hashedasset.eot);
  src: url(fontoctopus.af93096ee4f17f3ce6d6363747b45e97.hashedasset.eot#iefix) format('embedded-opentype'), url(fontoctopus.8acc0c7e45d7d6f690a7342d70180835.hashedasset.woff) format('woff'), url(fontoctopus.5eed1ba76b15097fa82835184a007327.hashedasset.ttf) format('truetype'), url(fontoctopus.84200d09800b6f6d6b36c793c47c0be5.hashedasset.svg) format('svg');
  font-weight: 400;
  font-style: normal;
}
.style_fontoctopus-octopus__32v55 {
  display: inline-block;
  font: normal normal normal 1em fontoctopus;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}
.style_fontoctopus-octopus__32v55:before {
  content: '\e800';
}
.style_monospace__jyQ90 {
  font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
  line-height: 1.2rem;
}
.style_rawLogContent__3wrPA {
  font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
  line-height: 1.2rem;
  font-size: 0.875rem;
  padding: 1rem;
  margin: 1rem;
  word-break: break-all;
  word-wrap: break-word;
  background-color: var(--hover);
  border: 0.0625rem solid var(--divider);
  border-radius: 0.125rem;
  white-space: pre;
  overflow-x: scroll;
}
