.style_machineIcon__3nsjB {
  width: 2.875rem;
  height: 3.125rem;
}
.style_machineImage__3hYE2 {
  background-color: var(--logoBackground);
  border-radius: 3.125rem;
  padding: 0.5rem;
  width: 3.125rem;
  height: 3.125rem;
  justify-content: center;
  align-items: center;
  display: flex;
}
