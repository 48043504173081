@media (max-width: 37.4rem) {
  .PackagingInstructionSelector_hide-sm__yZlYG {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .PackagingInstructionSelector_visible-sm__2OMcY {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .PackagingInstructionSelector_hide-md__1C3Jk {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .PackagingInstructionSelector_visible-md__2Hr7X {
    display: none !important;
  }
}
.PackagingInstructionSelector_centered__3j8U0 {
  display: flex;
  justify-content: center;
}
.PackagingInstructionSelector_outerContainer__E_0v7 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.PackagingInstructionSelector_container__2719_ {
  flex-direction: column;
  display: flex;
  justify-content: center;
}
.PackagingInstructionSelector_active__3-8eg {
  background-color: var(--mainText) !important;
}
.PackagingInstructionSelector_cardGroup__1skIq {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom: 2rem;
  justify-content: center;
}
.PackagingInstructionSelector_actions__K2eLv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}
.PackagingInstructionSelector_headingContainer__3pWyV {
  background-color: var(--whiteConstant);
  margin: 0;
  text-align: center;
}
.PackagingInstructionSelector_paperActions__3Mgfj {
  background-color: var(--whiteConstant);
  padding: 0.5rem 0 0 0;
}
.PackagingInstructionSelector_actions__K2eLv > * {
  margin-right: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.PackagingInstructionSelector_link__305xo {
  cursor: pointer;
}
.PackagingInstructionSelector_logo__1rHCL {
  display: none;
}
.PackagingInstructionSelector_groupHelp__qUk8p {
  margin: 0 1rem;
  display: flex;
  justify-content: center;
}
.PackagingInstructionSelector_groupHeading__2eT5K {
  text-align: center;
  padding: 0.5rem 1.125rem;
  font-size: 1.0625rem;
}
.PackagingInstructionSelector_contentContainer__3rye9 {
  min-height: 33rem;
}
.PackagingInstructionSelector_instructionsIntro__1Pv8N {
  margin-bottom: 0.5rem;
}
