.style_narrow__2vBSI {
  max-width: 8rem;
}
.style_summaryMap__39PC0 {
  top: -0.85rem;
}
.style_project__3OFcP {
  font-size: 1rem;
  display: flex;
  justify-content: left;
  align-items: center;
}
.style_project__3OFcP div {
  padding-right: 0.5rem;
}
.style_phasesEditHeader__3BDwb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.style_addPhaseActionContainer__3nzvC {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
