@media (max-width: 37.4rem) {
  .style_hide-sm__2TcUr {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__knRXq {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__2JRk2 {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__3mkLp {
    display: none !important;
  }
}
.style_content__1lZBb {
  color: var(--secondaryText) !important;
  padding: 0.5rem 1rem;
}
