@media (max-width: 37.4rem) {
  .CustomDialogFrames_hide-sm__29u98 {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .CustomDialogFrames_visible-sm__2O7US {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .CustomDialogFrames_hide-md__1RJ72 {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .CustomDialogFrames_visible-md__2zGb_ {
    display: none !important;
  }
}
.CustomDialogFrames_frame___nc4O {
  position: fixed;
  display: flex;
  flex-direction: column;
}
.CustomDialogFrames_commonFrame__hQklV {
  max-height: 100%;
  overflow-y: auto;
  border-radius: 4px;
}
.CustomDialogFrames_wrapper__REwpg {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
@media (max-width: 37.4rem) {
  .CustomDialogFrames_wrapper__REwpg {
    align-items: inherit;
  }
}
.CustomDialogFrames_opaque__3esx7 {
  background: #fff;
}
.CustomDialogFrames_padded__3dtgY {
  padding: 1em;
}
.CustomDialogFrames_fullscreen__1DeJ4 {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
}
.CustomDialogFrames_shadow__CIVg2 {
  box-shadow: var(--shadow25) 0rem 0.875rem 2.8125rem, var(--shadow22) 0rem 0.625rem 1.125rem;
}
.CustomDialogFrames_huge__315RB {
  top: 5%;
  left: 5%;
  bottom: 5%;
  right: 5%;
  box-shadow: var(--shadow25) 0rem 0.875rem 2.8125rem, var(--shadow22) 0rem 0.625rem 1.125rem;
}
@media (max-width: 37.4rem) {
  .CustomDialogFrames_huge__315RB {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
.CustomDialogFrames_medium__1w5AV {
  display: flex;
  flex-direction: column;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  width: 75%;
  max-width: 1000px;
}
@media (max-width: 79.9rem) {
  .CustomDialogFrames_medium__1w5AV {
    width: 100%;
  }
}
.CustomDialogFrames_small__104CS {
  max-width: 600px;
}
