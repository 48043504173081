.style_container__282b3 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.style_separator__1NIgZ {
  margin: 0 0.425rem 0 0.125rem;
}
.style_error__3RNUr {
  color: var(--dangerText);
  font-size: 0.75rem;
  margin-top: 0.125rem;
}
