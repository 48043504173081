.style_status__1ZDlk {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.125rem;
  font-size: 1.25rem;
  color: var(--whiteConstant);
}
.style_small__3FkU1 {
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.875rem;
}
.style_Queued__DeyMU {
  background-color: var(--infoConstant);
}
.style_Executing__3yznb {
  background-color: var(--infoConstant);
}
.style_Paused__lSLM3 {
  background-color: var(--alertConstant);
}
.style_TimedOut__VpLrB {
  background-color: var(--alertConstant);
}
.style_Failed__1oQpX {
  background-color: var(--dangerConstant);
}
.style_Canceled__ExnAj {
  background-color: var(--darkBackground);
  color: var(--darkText);
}
.style_Cancelling__1oF2r {
  background-color: var(--darkBackground);
  color: var(--darkText);
}
.style_Success__LkzoC {
  background-color: var(--successConstant);
}
.style_SuccessWithWarnings__o1p7G {
  background-color: var(--successConstant);
}
