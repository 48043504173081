.RunbookRunNowLayout_packageTableContainer__23Tl0 {
  overflow-x: auto;
}
.RunbookRunNowLayout_packageTableContainer__23Tl0 .RunbookRunNowLayout_packageTable__2k8xj {
  font-size: 1rem;
}
.RunbookRunNowLayout_packageTableContainer__23Tl0 .RunbookRunNowLayout_packageTable__2k8xj th {
  font-size: 0.875rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.RunbookRunNowLayout_packageTableContainer__23Tl0 .RunbookRunNowLayout_packageTable__2k8xj .RunbookRunNowLayout_packageTableRowColumn__5cn4R {
  vertical-align: middle !important;
  padding: 0.25rem 1.125rem;
}
.RunbookRunNowLayout_packageTableContainer__23Tl0 .RunbookRunNowLayout_packageTable__2k8xj .RunbookRunNowLayout_packageTableRowColumnName__1TMff {
  min-width: 10rem;
}
.RunbookRunNowLayout_packageTableContainer__23Tl0 .RunbookRunNowLayout_packageTable__2k8xj .RunbookRunNowLayout_packageColumn__2pBmR {
  width: 25%;
}
.RunbookRunNowLayout_packageTableContainer__23Tl0 .RunbookRunNowLayout_packageTable__2k8xj .RunbookRunNowLayout_latestColumn__34ZHx {
  width: 23%;
}
.RunbookRunNowLayout_packageTableContainer__23Tl0 .RunbookRunNowLayout_packageTable__2k8xj .RunbookRunNowLayout_lastColumn__1WvQF {
  width: 23%;
}
.RunbookRunNowLayout_packageTableContainer__23Tl0 .RunbookRunNowLayout_packageTable__2k8xj .RunbookRunNowLayout_specificColumn__79tBX {
  width: 29%;
}
.RunbookRunNowLayout_packageTableContainer__23Tl0 .RunbookRunNowLayout_packageTable__2k8xj .RunbookRunNowLayout_versionTextbox__2hwVm {
  width: 18rem !important;
}
.RunbookRunNowLayout_packageTableContainer__23Tl0 .RunbookRunNowLayout_packageTable__2k8xj .RunbookRunNowLayout_specificVersionDiv__3jwd1 {
  display: flex;
  align-items: center;
}
.RunbookRunNowLayout_packageTableContainer__23Tl0 .RunbookRunNowLayout_packageTable__2k8xj .RunbookRunNowLayout_inlineDiv__3B1aV {
  display: inline-block;
}
.RunbookRunNowLayout_packageTableContainer__23Tl0 .RunbookRunNowLayout_packageTable__2k8xj .RunbookRunNowLayout_inlineDiv__3B1aV .RunbookRunNowLayout_editVersionArea__1d-WZ {
  min-width: 6.875rem;
}
.RunbookRunNowLayout_packageTableContainer__23Tl0 .RunbookRunNowLayout_packageTable__2k8xj .RunbookRunNowLayout_radioButtonContainer__1OS5S {
  margin-top: -0.6rem;
}
.RunbookRunNowLayout_packageTableContainer__23Tl0 .RunbookRunNowLayout_packageTable__2k8xj .RunbookRunNowLayout_radioButtonContainer__1OS5S .RunbookRunNowLayout_myRadioButton__hHo4A {
  margin-right: 0.5rem;
}
.RunbookRunNowLayout_actionName__2AOVd {
  font-size: 0.875rem;
  color: var(--secondaryText);
}
