.style_checkbox__1sk0q {
  display: inline-block;
  margin-right: 2rem;
}
.style_optionsBar__21oPr {
  display: flex;
  align-items: center;
}
.style_tableColumn__30vWJ {
  padding-bottom: 0.5rem !important;
}
.style_versionRadio__3wV4R {
  margin-top: -1rem;
}
.style_loadMore__1dc7W {
  display: flex;
  justify-content: center;
}
.style_infoCircle__1P6Jw {
  color: var(--info);
  width: 1.5rem;
  margin-top: 1rem;
  display: flex;
}
.style_legend__2zcDo {
  margin-top: 0.5rem;
}
.style_legend__2zcDo .style_infoIcon__1i0Z- {
  color: var(--info);
}
