@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Regular.3e1af3ef546b9e6ecef9f3ba197bf7d2.hashedasset.ttf);
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Italic.42bbe4eefcde1297b11dc4b6491e9746.hashedasset.ttf);
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Light.fc84e998bc29b297ea20321e4c90b6ed.hashedasset.ttf);
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Medium.d08840599e05db7345652d3d417574a9.hashedasset.ttf);
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Bold.ee7b96fa85d8fdb8c126409326ac2d2b.hashedasset.ttf);
  font-weight: 600;
}
@font-face {
  font-family: 'fontoctopus';
  src: url(fontoctopus.af93096ee4f17f3ce6d6363747b45e97.hashedasset.eot);
  src: url(fontoctopus.af93096ee4f17f3ce6d6363747b45e97.hashedasset.eot#iefix) format('embedded-opentype'), url(fontoctopus.8acc0c7e45d7d6f690a7342d70180835.hashedasset.woff) format('woff'), url(fontoctopus.5eed1ba76b15097fa82835184a007327.hashedasset.ttf) format('truetype'), url(fontoctopus.84200d09800b6f6d6b36c793c47c0be5.hashedasset.svg) format('svg');
  font-weight: 400;
  font-style: normal;
}
.style_fontoctopus-octopus__3nJqn {
  display: inline-block;
  font: normal normal normal 1em fontoctopus;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}
.style_fontoctopus-octopus__3nJqn:before {
  content: '\e800';
}
.style_monospace__35aO8 {
  font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
  line-height: 1.2rem;
}
@media (max-width: 37.4rem) {
  .style_hide-sm__1Yiwy {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__3aJHz {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__21CUe {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__3UcFQ {
    display: none !important;
  }
}
@media (max-width: 37.4rem) {
  .style_logLine__2qZXD {
    /* mark.siedle - Flex hackery to get this working for mobile */
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
  .style_logLine__2qZXD > div {
    width: 100% !important;
  }
}
.style_topLevelBlock__1tFkC {
  margin: 1rem;
}
.style_messages__hSXPC {
  padding: 1rem;
}
.style_message__1JNhc {
  margin-right: 18rem;
  white-space: pre-wrap;
  word-break: break-word;
  /* mark.siedle - Needed or this can push out containers and stop sidebars from wrapping properly. */
  min-width: 10rem;
  /* mark.siedle - Needed or log lines can compress text to the extreme :) */
}
@media (max-width: 37.4rem) {
  .style_message__1JNhc {
    margin-right: unset;
  }
}
.style_category__3VZDG {
  width: 5rem;
  float: right;
}
.style_occurred__pcEkT {
  float: right;
  width: auto;
}
.style_gap__G1M7W {
  font-style: italic;
}
.style_elementError__2X-v2 {
  color: var(--dangerText);
}
.style_elementWarning__2eLGM {
  color: var(--alert);
  font-weight: 500;
}
.style_elementHighlight__2xcfP {
  color: var(--info);
  font-weight: 600;
}
.style_elementWait__DMwcJ {
  color: var(--info);
}
.style_elementVerbose__3lF3L {
  color: var(--secondaryText);
}
