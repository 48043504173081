.style_environmentList__1Us-9 {
  padding: 0!important;
}
.style_environmentList__1Us-9 div > button {
  padding: 16px 24px 16px 16px !important;
  justify-content: space-between !important;
  align-content: center !important;
  display: flex !important;
  flex-direction: row-reverse !important;
}
.style_phaseDeleteButton__2pWNc {
  margin-right: 1.4rem !important;
  margin-top: 0.5rem !important;
}
.style_sectionControl__cxJro {
  margin-top: 1rem;
}
.style_actionsMenu__19-G1 {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.style_anyEnvironment__BxPa_ {
  margin-top: 1rem;
  display: block;
  color: var(--primaryText);
}
.style_padUnder__1fiBN {
  margin-bottom: 0.25rem;
}
.style_missingResource__1Qji_ {
  color: var(--dangerText);
}
.style_strongText__13g8p {
  font-weight: strong;
}
