@media (max-width: 37.4rem) {
  .style_hide-sm__3id7t {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__2Hpsy {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__3oX6k {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__RQoqD {
    display: none !important;
  }
}
.style_sideMenu__3BP99 {
  padding: 0 1rem;
  width: 16.6rem;
  flex: 0 0 auto;
}
.style_breakword__3-CAY {
  word-wrap: break-word;
  word-break: break-word;
}
.style_name__1TQeI {
  word-wrap: break-word;
  word-break: break-word;
  font-size: 1.625rem;
  line-height: 1.5rem;
  min-width: 50%;
  padding-top: 1.5rem;
  line-height: 1.75rem;
  color: var(--primaryText);
}
.style_nameHorizontal__35eA2 {
  word-wrap: break-word;
  word-break: break-word;
  font-size: 1.625rem;
  line-height: 1.5rem;
  min-width: 50%;
}
.style_resourceType__30MEU {
  text-transform: uppercase;
  color: var(--secondaryText);
  font-size: 0.75rem;
}
.style_description__1eymc {
  word-wrap: break-word;
  word-break: break-word;
  padding-top: 1.5rem;
}
.style_preNav__3ZjJM {
  padding: 0.75rem 0;
}
.style_links__1DYqH {
  padding-top: 0.875rem;
}
.style_link__QNheo {
  display: block;
  font-size: 1rem;
  line-height: 2.5rem;
  text-align: left;
  color: var(--primaryText);
  text-decoration: none;
}
.style_link__QNheo > :active,
.style_link__QNheo > :visited {
  color: var(--primaryText);
}
a.style_link__QNheo:hover {
  color: var(--linkText);
  background: var(--sideMenuHover);
}
.style_selected__1bbco {
  color: var(--mainText) !important;
  font-weight: 500;
}
.style_stackedLayout__24p-X {
  display: flex;
  flex-direction: column;
}
.style_title__GBRNI {
  display: flex;
  flex-direction: row;
  margin: 0.5rem;
  align-items: center;
}
.style_logo__3exHx {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.style_horizontalLinks__MOenU {
  height: 2.5rem;
  margin: 0;
  padding-bottom: 1rem;
}
.style_horizontalLinks__MOenU ul {
  margin: 0;
}
.style_horizontalLinks__MOenU ul li {
  display: inline-block;
}
.style_horizontalLinks__MOenU ul li a {
  display: block;
  font-size: 1rem;
  line-height: 2.5rem;
  text-align: left;
  color: var(--primaryText);
  text-decoration: none;
  padding-right: 1rem;
  flex-direction: row;
  display: flex;
  align-content: center;
  align-items: center;
}
.style_horizontalLinks__MOenU ul li a > :active,
.style_horizontalLinks__MOenU ul li a > :visited {
  color: var(--primaryText);
}
.style_horizontalLinks__MOenU ul li a:hover {
  color: var(--primaryDark);
  background: var(--hover);
}
.style_horizontalLinks__MOenU ul li a em {
  font-size: 1.0625rem;
  padding: 0 0.5rem;
}
.style_sidebarLayout__16rt- {
  display: flex;
  margin-top: 0.875rem;
  margin-right: 1rem;
}
.style_sidebarLayoutContent__1x8ox {
  flex: 1 1 auto;
  display: flex;
  justify-content: space-around;
  padding: 0.125rem;
  min-width: 0;
}
.style_nestedNavLinks__3RHQX {
  padding-bottom: 0.25rem;
}
.style_nestedNavLinks__3RHQX .style_link__QNheo {
  line-height: 2rem;
  font-size: 0.9375rem;
  color: var(--ternaryText);
  padding-left: 0.5rem;
}
.style_nestedNavLinksParent__1Ml9D {
  color: var(--primaryText) !important;
}
.style_nestedNavLinksParent__1Ml9D a.style_selected__1bbco {
  color: var(--primaryText) !important;
}
.style_nestedNavLinksParentSelected__1oIi6 {
  font-weight: 500;
}
