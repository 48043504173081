.style_container__3lshD {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  min-width: 11.25rem;
  padding: 0.5rem;
  display: inline-flex;
}
.style_container__3lshD .style_additionalDetails__13hd6 {
  margin-left: 0.5rem;
}
.style_details__18OXX {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 0.5rem;
  width: 100%;
}
.style_snapshotName__1Hr-x,
.style_snapshotNotes__1Hmta {
  color: var(--ternaryText);
  font-weight: 400;
  font-size: 0.6875rem;
}
.style_snapshotNotes__1Hmta {
  margin: 0.5rem 0;
  padding: 0.5rem;
}
.style_date__2uyq5 {
  color: var(--primaryText);
  font-weight: 500;
  font-size: 0.9375rem;
  display: flex;
  align-items: center;
  width: 100%;
}
.style_taskLink__3Rlc5 {
  display: inherit;
}
.style_taskLink__3Rlc5:hover {
  background-color: var(--hover);
}
