.style_container__1vhSh {
  display: flex;
  align-items: center;
}
.style_separator__2na_M {
  margin: 0 0.125rem;
}
.style_ampm__1eoZ3 {
  margin-left: 0.1875rem;
}
.style_input__2gpRW {
  background-color: var(--paper1) !important;
}
.style_input__2gpRW + b,
.style_input__2gpRW ~ b {
  background-color: var(--pagingButtonBackground) !important;
  border-style: none !important;
}
.style_input__2gpRW + b:hover,
.style_input__2gpRW ~ b:hover {
  background-color: var(--pagingButtonBackgroundHover) !important;
}
.style_input__2gpRW + b:hover > i,
.style_input__2gpRW ~ b:hover > i,
.style_input__2gpRW + b:hover ~ i,
.style_input__2gpRW ~ b:hover ~ i {
  background-color: var(--whiteConstant) !important;
}
.style_input__2gpRW + b > i,
.style_input__2gpRW ~ b > i,
.style_input__2gpRW + b ~ i,
.style_input__2gpRW ~ b ~ i {
  background-color: var(--secondaryText) !important;
}
