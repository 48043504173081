@media (max-width: 37.4rem) {
  .style_hide-sm__206LZ {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__2LKp2 {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__2d2D3 {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__3L1HT {
    display: none !important;
  }
}
.style_emptyList__3ZEPt {
  padding: 1.25rem;
}
.style_headerBar__aJZIV {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.875rem;
  color: var(--ternaryText);
}
@media (max-width: 37.4rem) {
  .style_headerBar__aJZIV {
    flex-direction: column;
  }
}
.style_actionsMenu__3sBxu {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
