.style_description__36kF_ {
  color: var(--ternaryText);
  font-size: 0.8125rem;
  font-weight: 300;
  line-height: 1rem;
}
.style_description__36kF_ p {
  margin: 0.5rem 0;
  font-size: 0.8125rem;
  color: var(--ternaryText);
}
