@media (max-width: 37.4rem) {
  .style_hide-sm__1k2WH {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__pA755 {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__2jFql {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__KxSwt {
    display: none !important;
  }
}
.style_deploymentPart__3S1um {
  min-height: 5.1rem;
}
.style_divider__3WvLr {
  display: flex;
  align-items: center;
}
.style_actionSummary__3YcMQ {
  display: flex;
  flex-flow: row;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
.style_disabled__v-8vP * {
  color: var(--secondaryText);
}
.style_disabled__v-8vP * svg {
  opacity: 0.5;
}
.style_logo__1g6EB {
  order: 1;
}
.style_content__3f_zy {
  flex: 4 0;
  order: 2;
  margin-left: 1rem;
}
.style_contextualActions__1xpXg {
  order: 3;
}
.style_actions__3qeN4 {
  margin-left: 4.1rem;
  padding-left: 0;
}
.style_steps__2RCwM {
  padding-left: 1rem;
}
.style_index__2qG5Q {
  margin-right: 1rem;
  font-size: 1.0625rem;
}
.style_contentHeader__2jsv2 {
  margin-bottom: 0.5rem;
  font-weight: 500;
}
.style_contentBody__2QNaH {
  font-size: 0.875rem;
}
.style_environment__35E7G {
  text-align: right;
  padding-bottom: 0.25rem;
}
.style_scriptModulesStatus__PgUrL {
  font-size: 0.875rem;
  margin: 0;
}
.style_includedScriptModules__2kKHs {
  list-style: disc inside;
  color: var(--mainText);
}
.style_lifeCycleName__3RiCG {
  font-size: 1rem;
  font-weight: 600;
  margin-right: 1rem;
}
.style_noteList__PhX4l {
  list-style-type: disc;
  padding: 0 1rem;
  max-width: 36rem;
}
.style_stepTitle__OGtyI {
  display: flex;
  flex-direction: column;
}
.style_stepTitle__OGtyI .style_stepNumber__3dsaI {
  padding-right: 0.5rem;
}
.style_stepTitle__OGtyI .style_stepType__1yhjD {
  text-transform: uppercase;
  color: var(--secondaryText);
  font-size: 0.75rem;
  line-height: 1;
}
.style_row__3sV0A {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.style_row__3sV0A .style_container__2F3KD {
  width: 80%;
}
.style_row__3sV0A .style_images__2Qzul {
  width: 60%;
  align-items: center;
  text-align: center;
}
.style_row__3sV0A .style_images__2Qzul img {
  height: 5rem;
  width: auto;
  margin: 0.5rem 0;
}
.style_row__3sV0A .style_images__2Qzul div {
  text-align: center;
}
@media (max-width: 79.9rem) {
  .style_row__3sV0A .style_images__2Qzul div {
    text-align: left;
  }
}
@media (max-width: 79.9rem) {
  .style_row__3sV0A .style_images__2Qzul {
    width: 100%;
  }
}
@media (max-width: 79.9rem) {
  .style_row__3sV0A {
    flex-direction: column;
  }
}
.style_col__qAr-0 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.style_col__qAr-0 img {
  height: 5rem;
  width: auto;
  margin: 0.5rem 0;
}
.style_parentStepIcon__3LlOU {
  background-color: var(--parentStepBackground);
  border-radius: 5rem;
}
