.IconMenu_overflowButton__38Vw6 {
  font-size: 1.375rem;
  width: 3rem!important;
  height: 2.25rem !important;
  padding: 0!important;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--iconNeutral) !important;
}
.IconMenu_overflowButton__38Vw6:visited {
  color: var(--iconNeutral);
}
.IconMenu_overflowButton__38Vw6:hover {
  background-color: transparent !important;
  opacity: 0.5;
}
.IconMenu_disabledItem__HsRMT {
  cursor: not-allowed;
  color: var(--disabledButtonText);
}
