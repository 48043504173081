.style_nameCellContent__1DGwv {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
.style_spacer__JitSb {
  flex-grow: 1;
}
.style_value__30e5I {
  display: flex;
}
.style_promptedVariablePositionContainer__2xQTo {
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: center;
}
.style_promptedVariableIconSizeContainer__253aL {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
}
.style_promptedVariableIcon__2-1be {
  opacity: 0.5;
  color: var(--secondaryText);
}
