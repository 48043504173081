@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(MaterialIcons-Regular.e79bfd88537def476913f3ed52f4f4b3.hashedasset.eot);
  /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'), url(MaterialIcons-Regular.570eb83859dc23dd0eec423a49e147fe.hashedasset.woff2) format('woff2'), url(MaterialIcons-Regular.012cf6a10129e2275d79d6adac7f3b02.hashedasset.woff) format('woff'), url(MaterialIcons-Regular.a37b0c01c0baf1888ca812cc0508f6e2.hashedasset.ttf) format('truetype');
}
.Registrations_material-icons__2oXym {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}
h5 {
  margin-bottom: 0.25rem !important;
  font-weight: 600;
}
.Registrations_icon__1J_yD {
  margin-right: 0.25rem;
  position: relative;
  top: 1px;
  font-family: Material Icons;
}
.Registrations_overviewContainer__3rXnG {
  font-size: 1rem;
}
.Registrations_topicsContainer__1PKFg {
  font-size: 0.875rem;
}
.Registrations_topicsContainer__1PKFg a em {
  font-size: 0.75rem;
}
.Registrations_overviewHighlight__2xq6r {
  color: var(--primaryText);
  padding-top: 0.5rem;
  font-size: 0.875rem;
}
.Registrations_overviewHighlight__2xq6r img {
  width: 14.375rem !important;
  height: auto;
}
.Registrations_terms__1AcnK {
  border-top: 1px solid var(--divider);
  padding-top: 1rem;
  margin-top: 1rem;
}
.Registrations_terms__1AcnK div a,
.Registrations_terms__1AcnK .Registrations_note__13lAr a {
  font-weight: 500;
}
.Registrations_needHelp__2IFw8 h5:before {
  content: "\e86d";
  margin-right: 0.25rem;
  position: relative;
  top: 1px;
  font-family: Material Icons;
}
.Registrations_newToOctopus__1zmkp h5:before {
  content: "\e05e";
  margin-right: 0.25rem;
  position: relative;
  top: 1px;
  font-family: Material Icons;
}
.Registrations_tutorials__3t7K3 h5:before {
  content: "\e86d";
  margin-right: 0.25rem;
  position: relative;
  top: 1px;
  font-family: Material Icons;
}
.Registrations_docs__3gd_y h5:before {
  content: "\e02f";
  margin-right: 0.25rem;
  position: relative;
  top: 1px;
  font-family: Material Icons;
}
.Registrations_support__3cRbM h5:before {
  content: "\e253";
  margin-right: 0.25rem;
  position: relative;
  top: 1px;
  font-family: Material Icons;
}
.Registrations_webinar__CXQiX h5:before {
  content: "\e04b";
  margin-right: 0.25rem;
  position: relative;
  top: 1px;
  font-family: Material Icons;
}
.Registrations_video__ycN9H h5:before {
  content: "\e63a";
  margin-right: 0.25rem;
  position: relative;
  top: 1px;
  font-family: Material Icons;
}
.Registrations_helpInformation__3ruhU {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--primaryText);
}
