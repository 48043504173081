.style_dataGridContainer__W_foX {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
}
.style_gridItem__2F2MP {
  margin: 1.25rem;
}
.style_gridItem__2F2MP:hover {
  cursor: pointer;
}
.style_emptyList__iwv8P {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: var(--secondaryText);
}
.style_loadMore__3BbQC {
  text-align: center;
}
.style_pagingControlContainer__IWy1_ {
  margin: 1.5rem 1rem;
}
.style_disablePagingEvents__2NJM6 {
  pointer-events: none;
}
