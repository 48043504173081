@media (max-width: 37.4rem) {
  .style_hide-sm__1nhgz {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__2FtW6 {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__2NbUn {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__15P2b {
    display: none !important;
  }
}
.style_container__VMqlF {
  margin: 0.5rem 0;
  padding: 0 0.5rem;
}
.style_ok__2Ju2d {
  background-image: url(shipment-ok.028887f78ec34417d2bdfeb32dfdc47b.hashedasset.svg);
  line-height: 1.6rem;
  background-repeat: no-repeat;
  padding-left: 1.75rem;
  margin-left: 0;
  margin-bottom: 0.0625rem;
  background-size: 1.5rem auto;
}
.style_error__3lNId {
  background-image: url(shipment-error.69e7e20103678d835b8fc622a361bdae.hashedasset.svg);
  line-height: 1.6rem;
  background-repeat: no-repeat;
  padding-left: 1.75rem;
  margin-left: 0;
  margin-bottom: 0.0625rem;
  background-size: 1.5rem auto;
}
dfn {
  font-style: normal;
  color: var(--primaryText);
  margin-left: 0.5rem;
}
.style_buildInformationTitle__2dDrV {
  font-size: 1rem;
}
.style_buildInformationSummary__Xnuxp {
  color: var(--primaryText);
  font-size: 0.8125rem;
}
@media (max-width: 37.4rem) {
  .style_buildInformationSummary__Xnuxp {
    padding: 0;
  }
}
.style_buildInformationSummaryLabel__xTzCz {
  width: 12rem;
  text-align: left;
  font-weight: 600;
}
@media (max-width: 37.4rem) {
  .style_buildInformationSummaryLabel__xTzCz {
    text-align: initial;
  }
}
.style_buildInformationSummary__Xnuxp table {
  background-color: transparent;
}
.style_buildInformationSummary__Xnuxp tr:hover {
  background-color: transparent !important;
}
.style_buildInformationSummary__Xnuxp tr {
  border: 0 !important;
}
.style_buildInformationSummary__Xnuxp td {
  padding: 0.125rem 0 !important;
}
