@media (max-width: 37.4rem) {
  .style_hide-sm__1Qmzr {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__3YHWe {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__3fMdk {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__211Zl {
    display: none !important;
  }
}
.style_panel__1O0D0 {
  position: relative;
  margin: 1rem;
}
.style_panel__1O0D0 .style_header__2C1hM {
  margin: 0;
  padding: 0 1.25rem;
  text-align: center;
}
.style_panel__1O0D0 .style_header__2C1hM img {
  max-width: 90%;
  height: auto;
}
.style_panel__1O0D0 .style_body__94oH2 {
  padding: 1.25rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.1875rem;
}
.style_panel__1O0D0 .style_body__94oH2 ol,
.style_panel__1O0D0 .style_body__94oH2 ul {
  padding-left: 2.5rem;
}
.style_panel__1O0D0 .style_body__94oH2 ul {
  list-style: square;
}
.style_panel__1O0D0 .style_body__94oH2 ol {
  list-style: decimal;
}
.style_panelCard__1p05w {
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
  margin-bottom: 1.875rem;
  position: relative;
  background-color: var(--whiteConstant);
  box-shadow: var(--shadow20) 0rem 0.1875rem 0.1875rem -0.125rem, var(--shadow14) 0rem 0.1875rem 0.25rem 0rem, var(--shadow12) 0rem 0.0625rem 0.5rem 0rem;
  background: var(--whiteConstant);
  transition: background-color 0.1s ease-in;
}
.style_panelCard__1p05w .style_header__2C1hM {
  margin: 0;
  padding: 0 1.25rem;
}
.style_panelCard__1p05w .style_header__2C1hM img {
  max-width: 80%;
  max-height: 80%;
}
.style_panelCard__1p05w .style_body__94oH2 {
  padding: 1.25rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.1875rem;
}
.style_panelCard__1p05w .style_body__94oH2 ol,
.style_panelCard__1p05w .style_body__94oH2 ul {
  padding-left: 2.5rem;
}
.style_panelCard__1p05w .style_body__94oH2 ul {
  list-style: square;
}
.style_panelCard__1p05w .style_body__94oH2 ol {
  list-style: decimal;
}
.style_onboardingOuterContainer__1fR5- {
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
}
.style_onboardingBlurred__2yW_o {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  /* filter doesn't work for IE11, known issue, we fallback to opacity only in this case */
  opacity: 0.8;
  transition: opacity 300ms ease-out;
  -moz-transition: opacity 300ms ease-out;
  -webkit-transition: opacity 300ms ease-out;
}
.style_onboardingBlurred__2yW_o:hover {
  -webkit-filter: unset;
  filter: unset;
  opacity: 1;
  transition: opacity 300ms ease-in;
  -moz-transition: opacity 300ms ease-in;
  -webkit-transition: opacity 300ms ease-in;
}
.style_onboardingContainer__1bjX1 {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  color: var(--ternaryText);
  font-size: 0.875rem;
}
.style_header__2C1hM {
  text-align: center;
  width: 100%;
  color: var(--primaryText);
  font-weight: 500;
}
.style_intro__31DCD {
  color: var(--ternaryText);
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  text-align: center;
}
.style_learnMore__1noBb {
  color: var(--ternaryText);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.style_onboardingImage__1NVLQ {
  text-align: center;
  width: 100%;
  margin: 1.5rem 0;
}
.style_onboardingCreateFirst__2rhf5 {
  text-align: center;
  font-size: 0.8125rem;
  color: var(--ternaryText);
  margin-top: 0.5rem;
}
.style_panels__2smTX {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.style_panels__2smTX > * {
  flex-grow: 1;
  max-width: 37.5rem;
  box-sizing: border-box;
}
@media (max-width: 37.4rem) {
  .style_panels__2smTX > * {
    width: 100%;
  }
  .style_onboardingOuterContainer__1fR5- {
    padding: 0.25rem 0.5rem;
    width: 100%;
  }
}
