.style_internalLink__hWOg_ {
  color: var(--linkText);
  text-decoration: none;
}
.style_internalLink__hWOg_:hover {
  color: var(--linkTextHover);
}
.style_listInternalLink__2JIcM {
  color: var(--primaryText);
}
.style_listInternalLink__2JIcM:hover,
.style_listInternalLink__2JIcM:focus {
  color: var(--primaryText);
}
