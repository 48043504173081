.style_components__3gCHe {
  margin-top: 0;
}
.style_components__3gCHe li {
  margin-top: 0.5rem;
}
.style_entry__2AQtR {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.style_entry__2AQtR ul.style_bulletList__kmQxO {
  margin-left: 3rem;
}
.style_entry__2AQtR ul.style_bulletList__kmQxO li {
  list-style: disc;
}
.style_styleGuide__2HpTU {
  margin-top: 2rem;
  margin-left: 2rem;
  display: flex;
  flex-direction: row;
}
.style_styleGuide__2HpTU > ul {
  list-style: none;
}
.style_styleGuide__2HpTU > ul > li {
  margin-bottom: 5px;
}
.style_heading__2OWNo {
  text-align: center;
}
.style_content__10BoA {
  margin-left: 2rem;
}
.style_context__11Q4q {
  display: block;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: var(--hover);
  font-size: 0.875rem;
  color: var(--ternaryText);
}
.style_context__11Q4q p {
  font-size: 0.875rem;
  color: var(--ternaryText);
}
thead {
  font-weight: 600;
}
.style_swatch__2HR0c {
  width: 1.5rem;
  height: 1.5rem;
  border: 0.0625rem solid var(--divider);
}
.style_white__1T-t4 {
  background: var(--whiteConstant);
}
.style_primaryText__7haAm {
  background: var(--primaryText);
}
.style_secondaryText__1cTz3 {
  background: var(--secondaryText);
}
.style_primary__26ziI {
  background: var(--mainBackground);
}
.style_primaryLight__s7Vcz {
  background: var(--primaryLight);
}
.style_primaryDark__2ociK {
  background: var(--primaryDark);
}
.style_primaryDarkest__3NoBI {
  background: var(--primaryDarkest);
}
.style_readonlyTextWithStrikethrough__1yTCM > input {
  text-decoration: line-through;
  color: var(--secondaryText) !important;
}
