.OperationsOverviewLayout_headerColumn__1g2Fg {
  width: 25%;
  padding: 0;
}
.OperationsOverviewLayout_emptyCell__3w8-d {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  font-size: 0.875rem;
}
.OperationsOverviewLayout_filterControl__Evlj0 {
  width: 15rem !important;
}
.OperationsOverviewLayout_runBy__3vsFy {
  font-size: 0.875rem;
  color: var(--secondaryText);
  margin: 0.25rem 0;
}
.OperationsOverviewLayout_customListItem__1kYEv {
  font-size: 0.9375rem;
  line-height: 1.18rem;
  color: var(--primary);
  margin: 0.25rem 0;
  font-weight: 400;
}
.OperationsOverviewLayout_resultsTableRow__1R32T {
  border-top: unset!important;
}
.OperationsOverviewLayout_resultsTableHeaderRow__YvxDk {
  border-top: unset!important;
  border-bottom: unset!important;
}
.OperationsOverviewLayout_nextScheduledRow__iE2Jj {
  margin-left: 2rem;
}
