.style_rowHeader__3g1oG {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  justify-content: left;
  font-weight: 500;
}
.style_blockAlert__kvIIY {
  font-size: 1.3rem;
}
.style_blockAlertIcon__2u16_ {
  font-size: 1.25rem;
  opacity: 0.2;
}
.style_rowCell__2rNBW {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.style_disabled__1M3Qi * {
  color: var(--secondaryText);
}
