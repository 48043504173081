@media (max-width: 37.4rem) {
  .ActionTemplateCategory_hide-sm__12x5t {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .ActionTemplateCategory_visible-sm__2ulGE {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .ActionTemplateCategory_hide-md__vNj1S {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .ActionTemplateCategory_visible-md__3wXJ6 {
    display: none !important;
  }
}
.ActionTemplateCategory_actionCategory__qmvD7 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
  padding: 0.5rem;
  background: var(--paper2);
  box-shadow: var(--shadow20) 0rem 0.125rem 0.0625rem -0.0625rem, var(--shadow14) 0rem 0.0625rem 0.0625rem 0rem, var(--shadow12) 0rem 0.0625rem 0.1875rem 0rem;
  border-radius: 0.25rem;
  height: 6.75rem;
  position: relative;
  user-select: none;
  -ms-user-select: none;
}
.ActionTemplateCategory_actionCategory__qmvD7:hover {
  background-color: var(--paper3);
}
.ActionTemplateCategory_actionCategory__qmvD7 svg {
  fill: var(--iconDark);
}
.ActionTemplateCategory_isSelectable__2YnmD:hover,
.ActionTemplateCategory_isSelectable__2YnmD:focus {
  cursor: pointer;
  box-shadow: var(--shadow20) 0rem 0.1875rem 0.1875rem -0.125rem, var(--shadow14) 0rem 0.1875rem 0.25rem 0rem, var(--shadow12) 0rem 0.0625rem 0.5rem 0rem;
}
.ActionTemplateCategory_blurred__2cxfW {
  opacity: 0.2;
}
.ActionTemplateCategory_categoryName__2Vt76 {
  color: var(--mainText);
  text-align: center;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 5.9375rem;
}
.ActionTemplateCategory_active__2EKFz {
  border: 0.125rem solid transparent;
  background-color: var(--mainBackground);
}
.ActionTemplateCategory_active__2EKFz .ActionTemplateCategory_categoryName__2Vt76 {
  color: var(--whiteConstant);
}
.ActionTemplateCategory_active__2EKFz svg {
  fill: var(--whiteConstant);
}
.ActionTemplateCategory_active__2EKFz:hover {
  background-color: var(--mainBackground);
}
.ActionTemplateCategory_inactive__2LTez {
  border: 0.125rem solid transparent;
}
.ActionTemplateCategory_activeIcon__2Tryp {
  position: absolute;
  top: -0.65rem;
  right: -0.65rem;
  background: var(--iconLight);
  border-radius: 1.875rem;
  height: 1.43rem;
  width: 1.43rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ActionTemplateCategory_activeIcon__2Tryp svg {
  margin-top: 0.0625rem;
  fill: var(--mainBackground);
}
