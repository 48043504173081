.style_dragHandlePositioningContainer__13aWj {
  position: relative;
  width: 0;
  height: 100%;
}
.style_dragHandleDraggableArea__1yPBN {
  position: absolute;
  top: 0.25rem;
  bottom: 0.25rem;
  left: -0.5rem;
  width: 1rem;
  cursor: col-resize;
  display: flex;
  justify-content: center;
}
.style_dragHandleIndicator__1Oj-V {
  width: 0.0625rem;
}
.style_dragHandleIndicatorLight__lrl9A {
  background-color: var(--divider);
}
.style_dragHandleIndicatorDark__37gpk {
  background-color: var(--shadow60);
}
