@media (max-width: 37.4rem) {
  .LastPublishedChip_hide-sm__2nybS {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .LastPublishedChip_visible-sm__2ALpn {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .LastPublishedChip_hide-md__3Qoqr {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .LastPublishedChip_visible-md__2TwBK {
    display: none !important;
  }
}
.LastPublishedChip_lastPublishedTooltipContent__3Fj3z {
  text-align: left;
}
.LastPublishedChip_lastPublishedTooltipContainer__27aGO {
  margin-left: 0.5rem;
}
@media (max-width: 37.4rem) {
  .LastPublishedChip_lastPublishedTooltipContainer__27aGO {
    margin-left: unset;
  }
}
.LastPublishedChip_formTitle__25BfG {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 37.4rem) {
  .LastPublishedChip_formTitle__25BfG {
    flex-direction: column;
  }
}
