.style_scopesContainer__1SMig {
  align-items: center;
  display: inline-flex;
  height: 100%;
  width: 100%;
}
.style_clickable__3V6tf {
  cursor: pointer;
}
.style_scopeCell__2uAHN {
  height: 100%;
}
.style_clickContainer__27Bhp {
  height: 100%;
}
.style_chipContainer__1WFEF {
  display: inline-flex;
  align-items: center;
}
.style_hidden__mIE9h {
  visibility: hidden;
}
.style_allChipsContainer__of23K {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.style_focusManagedWrapper__1_UH2 {
  height: 100%;
}
.style_defineScope__xspCp {
  cursor: inherit;
  overflow: hidden;
  color: var(--secondaryText);
}
