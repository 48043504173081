.style_box__1ILHY {
  padding: 0.5rem;
  align-self: stretch;
  font-weight: 400;
  margin-right: 0.5rem;
}
.style_taskSucceeded__1Gmxs {
  padding: 0.5rem;
  align-self: stretch;
  font-weight: 400;
  margin-right: 0.5rem;
  color: var(--successText);
  background-color: var(--successHighlight);
}
.style_taskSucceeded__1Gmxs .style_title__UwJjE {
  color: var(--successText);
}
.style_taskFailed__eD3aY {
  padding: 0.5rem;
  align-self: stretch;
  font-weight: 400;
  margin-right: 0.5rem;
  color: var(--dangerText);
  background-color: var(--dangerHighlight);
}
.style_taskFailed__eD3aY .style_title__UwJjE {
  color: var(--dangerText);
}
.style_taskTime__1Y3GB {
  margin-right: 0.5rem;
}
