@media (max-width: 37.4rem) {
  .hide-sm {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .visible-sm {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .hide-md {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .visible-md {
    display: none !important;
  }
}
@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Regular.3e1af3ef546b9e6ecef9f3ba197bf7d2.hashedasset.ttf);
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Italic.42bbe4eefcde1297b11dc4b6491e9746.hashedasset.ttf);
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Light.fc84e998bc29b297ea20321e4c90b6ed.hashedasset.ttf);
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Medium.d08840599e05db7345652d3d417574a9.hashedasset.ttf);
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Bold.ee7b96fa85d8fdb8c126409326ac2d2b.hashedasset.ttf);
  font-weight: 600;
}
@font-face {
  font-family: 'fontoctopus';
  src: url(fontoctopus.af93096ee4f17f3ce6d6363747b45e97.hashedasset.eot);
  src: url(fontoctopus.af93096ee4f17f3ce6d6363747b45e97.hashedasset.eot#iefix) format('embedded-opentype'), url(fontoctopus.8acc0c7e45d7d6f690a7342d70180835.hashedasset.woff) format('woff'), url(fontoctopus.5eed1ba76b15097fa82835184a007327.hashedasset.ttf) format('truetype'), url(fontoctopus.84200d09800b6f6d6b36c793c47c0be5.hashedasset.svg) format('svg');
  font-weight: 400;
  font-style: normal;
}
.fontoctopus-octopus {
  display: inline-block;
  font: normal normal normal 1em fontoctopus;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}
.fontoctopus-octopus:before {
  content: '\e800';
}
.monospace {
  font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
  line-height: 1.2rem;
}
h1 {
  font-size: 1.625rem;
  font-weight: 400;
  color: var(--primaryText);
}
@media (max-width: 37.4rem) {
  h1 {
    font-size: 1.4375rem;
  }
}
h2 {
  font-size: 1.25rem;
  font-weight: 400;
  color: var(--primaryText);
}
@media (max-width: 37.4rem) {
  h2 {
    font-size: 1.0625rem;
  }
}
h3 {
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--primaryText);
}
@media (max-width: 37.4rem) {
  h3 {
    font-size: 1.0625rem;
  }
}
h4 {
  font-size: 1rem;
  font-weight: 500;
  margin: 1rem 0 0.5rem 0;
  color: var(--primaryText);
}
h5 {
  font-size: 0.8125rem;
  font-weight: 400;
  margin: 1rem 0 0.5rem 0;
  color: var(--primaryText);
}
hr {
  display: block;
  height: 0.0625rem;
  border: 0;
  border-top: solid 0.0625rem var(--divider);
  margin: 1em 0;
  padding: 0;
}
a {
  color: var(--linkText);
}
a:hover {
  color: var(--linkTextHover);
}
p {
  margin: 0.75rem 0;
  color: var(--primaryText);
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px var(--whiteConstant) inset !important;
}
input::-ms-clear {
  width: 0;
  height: 0;
}
small {
  font-size: 0.875rem;
  line-height: 1.1875rem;
}
.secondary {
  color: var(--secondaryText);
}
code {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  color: var(--codeText);
  background-color: var(--codeHighlight);
  border-radius: 0.125rem;
  padding: 0.125rem 0.25rem;
  line-height: normal;
}
pre {
  background-color: var(--codeHighlight);
  border: 0.0625rem solid var(--divider);
  padding: 0.4rem;
  margin: 0 0 0.4rem;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
}
html {
  font-size: 100%;
  color: var(--primaryText);
}
body {
  font-size: 1rem;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  overflow: auto;
  margin: 0;
  padding: 0;
  background: var(--primaryBackground);
  letter-spacing: 0.25px;
  line-height: 1.25rem;
}
a {
  text-decoration: none;
}
ul, ol {
  list-style-type: none;
  padding: 0;
}
code {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  color: var(--codeText);
  background-color: var(--codeHighlight);
  border-radius: 0.125rem;
  padding: 0.125rem 0.25rem;
  line-height: normal;
}
pre {
  background-color: var(--codeHighlight);
  border: 0.0625rem solid var(--divider);
  padding: 0.4rem;
  margin: 0 0 0.4rem;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
}
