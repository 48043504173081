@media (max-width: 37.4rem) {
  .style_hide-sm__CyH25 {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__15-aY {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__2Ii4N {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__2Suxu {
    display: none !important;
  }
}
.style_container__C3zvD {
  display: flex;
  padding: 1rem 1rem;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 4.25rem;
}
.style_titleContainer__2bT9l {
  display: flex;
  flex-direction: column;
}
.style_header__bIe6n {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  color: var(--primaryText);
}
.style_header__bIe6n .style_helpIconContainer__26xCP {
  margin-left: 0.25rem;
  transform: scale(0.8);
}
.style_header__bIe6n .style_helpIconContainerClosed__3lZ5f {
  opacity: 0.4;
}
.style_title__3MVJY {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0.25rem 0;
}
.style_logo__GZM0- {
  margin-right: 0.5rem;
}
.style_refreshContainer__1qNcE {
  position: relative;
  height: 1.75rem;
  opacity: 0.8;
}
.style_refreshIndicator__2Vt5V {
  width: 2.5rem;
}
.style_breadcrumbContainer__1BPRC {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 56.25rem) {
  .style_sidebarText__fw05L {
    display: none;
  }
}
.style_sidebarTextToBackground__27UC1 {
  opacity: 0.5;
}
.style_pageTitleLink__3TzEq {
  color: var(--mainText);
  text-decoration: none;
}
.style_pageTitleLink__3TzEq > :active,
.style_pageTitleLink__3TzEq > :visited {
  color: var(--mainText);
}
.style_pageTitleLink__3TzEq:hover {
  color: var(--mainText);
}
