@media (max-width: 37.4rem) {
  .style_hide-sm__1lge_ {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__2fgqz {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__3XZSR {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__1pP2I {
    display: none !important;
  }
}
.style_projectVariablesContainer__2bdDZ {
  width: 100%;
}
.style_expanderTitle__38s8C {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.style_helpText__1-E28 {
  color: var(--secondaryText);
}
.style_variableContainer__oqZlc:not(:first-child) {
  border-top: 0.0625rem solid var(--divider);
}
.style_cardTitle__2dwLE {
  display: flex;
  align-items: center;
  line-height: 1rem;
  border-top: 0.0625rem solid var(--divider);
  font-size: 0.8125rem !important;
  min-height: 4rem;
  padding: 0rem 3rem 0rem 1rem;
}
.style_cardTitle__2dwLE h3 {
  display: inline;
}
.style_cardTitle__2dwLE .style_projectLogoContainer__1Az5K {
  flex-shrink: 0;
}
.style_cardTitle__2dwLE .style_projectEnvironmentVariableTitle__1k2Bh {
  font-weight: 500;
  font-size: 1.0625rem !important;
}
.style_missingVariableIcon__16vmz {
  color: var(--alert);
}
.style_libraryVariableSetContainer__3C1KN {
  flex-grow: 1;
}
.style_libraryWarning__2GaFx {
  display: inline;
  left: 0.25rem;
  position: relative;
}
.style_projectCardWarning__187MV {
  font-size: 1.2rem;
  align-self: center;
  padding-left: 0.25rem;
}
.style_projectName__28VUR {
  width: 22rem;
  font-weight: 500;
  margin-left: 0.5rem;
  display: flex;
  flex-direction: row;
  min-width: 10rem;
}
.style_projectName__28VUR div:nth-child(1) {
  max-width: 20rem;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1 20rem;
}
@media (max-width: 79.9rem) {
  .style_projectName__28VUR {
    flex-grow: 1;
    flex-direction: column;
    width: unset;
    height: 2.7em;
  }
  .style_projectName__28VUR div:nth-child(1) {
    max-width: unset;
  }
}
.style_projectNameAndWarningIconContainer__6xp5l {
  display: flex;
  flex-direction: row;
}
