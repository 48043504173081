.MemberRow_user__1gZFC {
  display: flex;
  align-items: center;
}
.MemberRow_userName__11v-L {
  margin-left: 0.5rem;
}
.MemberRow_groupImage__1iF3C {
  width: 1.5rem;
  height: 1.5rem;
}
