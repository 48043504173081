@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Regular.3e1af3ef546b9e6ecef9f3ba197bf7d2.hashedasset.ttf);
  font-weight: 400;
}
@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Italic.42bbe4eefcde1297b11dc4b6491e9746.hashedasset.ttf);
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Light.fc84e998bc29b297ea20321e4c90b6ed.hashedasset.ttf);
  font-weight: 300;
}
@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Medium.d08840599e05db7345652d3d417574a9.hashedasset.ttf);
  font-weight: 500;
}
@font-face {
  font-family: 'Roboto';
  src: url(Roboto-Bold.ee7b96fa85d8fdb8c126409326ac2d2b.hashedasset.ttf);
  font-weight: 600;
}
@font-face {
  font-family: 'fontoctopus';
  src: url(fontoctopus.af93096ee4f17f3ce6d6363747b45e97.hashedasset.eot);
  src: url(fontoctopus.af93096ee4f17f3ce6d6363747b45e97.hashedasset.eot#iefix) format('embedded-opentype'), url(fontoctopus.8acc0c7e45d7d6f690a7342d70180835.hashedasset.woff) format('woff'), url(fontoctopus.5eed1ba76b15097fa82835184a007327.hashedasset.ttf) format('truetype'), url(fontoctopus.84200d09800b6f6d6b36c793c47c0be5.hashedasset.svg) format('svg');
  font-weight: 400;
  font-style: normal;
}
.taskLogBlock_fontoctopus-octopus__1MZfo {
  display: inline-block;
  font: normal normal normal 1em fontoctopus;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  speak: none;
}
.taskLogBlock_fontoctopus-octopus__1MZfo:before {
  content: '\e800';
}
.taskLogBlock_monospace__2IIub {
  font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
  line-height: 1.2rem;
}
.taskLogBlock_topLevelBlock__2oju2 {
  margin: 1rem;
}
.taskLogBlock_block__1PjJ8 {
  border: thin solid var(--secondaryText);
  border-radius: 0.125rem;
  margin: 1rem 0.25rem;
  font-size: 0.875rem;
}
.taskLogBlock_lines__1kn-y {
  font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
  line-height: 1.2rem;
  font-size: 0.875rem;
  margin: 1rem 1rem 1rem 0;
  min-height: 0.5rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.taskLogBlock_blockHeader__1IZuQ {
  background: var(--primaryText);
  color: var(--whiteConstant);
  padding: 0.5rem 0.4rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  border-bottom: inherit;
  margin-bottom: -0.0625rem;
  cursor: pointer;
}
.taskLogBlock_blockExpanderIcon__cQ4sI {
  margin-right: 0.4rem;
}
.taskLogBlock_blockPending__1kC0l {
  border: thin solid var(--secondaryText);
  border-radius: 0.125rem;
  margin: 1rem 0.25rem;
  font-size: 0.875rem;
  border-color: var(--secondaryBackground);
}
.taskLogBlock_blockPending__1kC0l > .taskLogBlock_blockHeader__1IZuQ {
  background: var(--primaryBackground);
  color: var(--ternaryText);
}
.taskLogBlock_blockRunning__G5Ujk {
  border: thin solid var(--secondaryText);
  border-radius: 0.125rem;
  margin: 1rem 0.25rem;
  font-size: 0.875rem;
  border-color: var(--secondaryBackground);
}
.taskLogBlock_blockRunning__G5Ujk > .taskLogBlock_blockHeader__1IZuQ {
  background: var(--primaryBackground);
  color: var(--infoText);
}
.taskLogBlock_blockSuccess__1Ndg0 {
  border: thin solid var(--secondaryText);
  border-radius: 0.125rem;
  margin: 1rem 0.25rem;
  font-size: 0.875rem;
  border-color: var(--successText);
}
.taskLogBlock_blockSuccess__1Ndg0 > .taskLogBlock_blockHeader__1IZuQ {
  background: var(--successBackground);
  color: var(--successText);
}
.taskLogBlock_blockSuccessWithWarning__1QA3u {
  border: thin solid var(--secondaryText);
  border-radius: 0.125rem;
  margin: 1rem 0.25rem;
  font-size: 0.875rem;
  border-color: var(--successText);
}
.taskLogBlock_blockSuccessWithWarning__1QA3u > .taskLogBlock_blockHeader__1IZuQ {
  background: var(--successBackground);
  color: var(--successText);
}
.taskLogBlock_blockSuccessWithWarning__1QA3u > .taskLogBlock_blockHeader__1IZuQ {
  color: var(--alertText);
}
.taskLogBlock_blockSkipped__JNNSM {
  border: thin solid var(--secondaryText);
  border-radius: 0.125rem;
  margin: 1rem 0.25rem;
  font-size: 0.875rem;
  border-color: var(--secondaryBackground);
}
.taskLogBlock_blockSkipped__JNNSM > .taskLogBlock_blockHeader__1IZuQ {
  background: var(--primaryBackground);
  color: var(--secondaryText);
}
.taskLogBlock_blockFailed__2fG-q {
  border: thin solid var(--secondaryText);
  border-radius: 0.125rem;
  margin: 1rem 0.25rem;
  font-size: 0.875rem;
  border-color: var(--dangerText);
}
.taskLogBlock_blockFailed__2fG-q > .taskLogBlock_blockHeader__1IZuQ {
  background: var(--dangerBackground);
  color: var(--dangerText);
}
.taskLogBlock_blockCanceled__A86ny {
  border: thin solid var(--secondaryText);
  border-radius: 0.125rem;
  margin: 1rem 0.25rem;
  font-size: 0.875rem;
  border-color: var(--secondaryBackground);
}
.taskLogBlock_blockCanceled__A86ny > .taskLogBlock_blockHeader__1IZuQ {
  background: var(--primaryBackground);
  color: var(--secondaryText);
}
.taskLogBlock_body__2dZAa {
  min-height: 0.5rem;
  margin-left: 1rem;
}
.taskLogBlock_progress__1qumQ {
  font-size: 0.7rem;
}
