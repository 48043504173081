.style_select__Des50 {
  width: 100% !important;
  min-width: 0 !important;
  overflow: visible;
  display: flex;
  flex-direction: column;
}
.style_filter__1hF44 {
  margin: 0 1rem;
}
.style_container__3QW8o {
  width: 100%;
}
.style_empty__2C1fG {
  padding: 0.625rem;
}
