.style_container__2sKzz {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.25rem;
  color: var(--tenantCount);
  font-size: 0.69rem;
  font-weight: 500;
  padding: 0 0.25rem;
  white-space: nowrap;
  border-radius: 0.25em;
  background: var(--secondaryText);
}
.style_progress__2MdP7 {
  visibility: visible;
}
.style_expected__Abpad {
  visibility: visible;
}
