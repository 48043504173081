.style_variableSetLink__TLiaG {
  margin-left: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.style_header__2fFOU {
  cursor: pointer;
  position: relative;
  height: 100%;
}
.style_busy__2R0NW {
  position: absolute;
  z-index: 1;
  width: 100%;
}
.style_textAndButtons__231Q9 {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}
.style_editIcon__skc1v {
  margin-left: 1rem;
  align-items: center;
  opacity: 0.5;
  color: var(--secondaryText);
}
.style_editIcon__skc1v:hover {
  opacity: 1;
}
.style_expandIcon__iHX89,
.style_expandIcon__iHX89:hover {
  background-color: transparent!important;
  flex-grow: 0;
  justify-content: flex-end;
}
.style_expandClosed__2OZRE {
  transform: rotate(0deg) !important;
  flex-grow: 0;
}
.style_expandOpen__2Cpy7 {
  transform: rotate(180deg) !important;
  flex-grow: 0;
}
