.style_note__3uJBI {
  font-family: Roboto;
  font-size: 0.75rem;
  text-align: left;
  font-weight: 300;
  margin-bottom: 0.5rem;
  color: var(--ternaryText);
}
.style_note__3uJBI a {
  color: var(--mainText);
}
.style_note__3uJBI .style_checkboxNote__14Pnl {
  display: none;
}
.style_header__33m1I {
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--ternaryText);
}
