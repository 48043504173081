@media (max-width: 37.4rem) {
  .style_hide-sm__chGX1 {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__1YrXB {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__3PoTp {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__2lDmd {
    display: none !important;
  }
}
.style_empty__2H3E0 {
  font-size: 1rem;
  margin: 0 1rem;
  line-height: 1.5rem;
}
.style_paperLayoutOverride__2MGG0 > div:first-child {
  box-shadow: unset;
  border-top: 0.0625rem solid var(--divider);
  border-bottom: 0.0625rem solid var(--divider);
  margin: 0;
  border-radius: unset;
}
.style_cardTitleContainer__iqN6r {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  padding: 0 1rem;
  cursor: pointer;
}
.style_cardTitleContainer__iqN6r .style_environmentIcon__2LaPM {
  display: flex;
  padding-right: 0.4rem;
  width: 1.5rem;
  min-width: 1.5rem;
  height: auto;
}
.style_cardTitleContainer__iqN6r .style_environmentName__1kovj {
  color: var(--mainText);
  font-weight: 500;
  margin: 0.25rem 0;
  padding-right: 0.4rem;
  white-space: nowrap;
  font-size: 1rem;
}
.style_cardTitleContainer__iqN6r .style_environmentMachinesCount__3tkGo {
  color: var(--secondaryText);
  margin: 0.25rem 0;
  padding-right: 0.4rem;
}
.style_cardTitleContainer__iqN6r .style_environmentSummaryCounts__3Ds0W {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: 5rem;
}
@media (max-width: 37.4rem) {
  .style_cardTitleContainer__iqN6r .style_environmentSummaryCounts__3Ds0W {
    display: none;
  }
}
.style_cardTitleContainer__iqN6r .style_environmentSummaryCounts__3Ds0W .style_summaryCount__15cRR {
  margin: 0.25rem 0;
  padding-right: 0.4rem;
  display: flex;
  align-items: center;
  font-size: 0.8125rem !important;
  text-transform: uppercase;
}
.style_cardTitleContainer__iqN6r .style_environmentOverflowActions__2le3y {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 3rem;
  margin: auto;
  width: 3rem;
  height: 2.25rem;
}
.style_machineIcon__1aZiu {
  width: 2.438rem;
  height: 2.563rem;
  background-color: white;
  border-radius: 30px;
  padding: 0.5rem;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.style_healthStatusIcon__1mDbb {
  margin-right: 0.25rem;
  background: var(--logoBackground);
  border-radius: 1.875rem;
  border: 0.125rem solid var(--logoBackground);
}
.style_healthStatusCardTitleContainer__1ZjLx {
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  padding: 0;
  margin-left: 1.5rem;
}
@media (max-width: 37.4rem) {
  .style_healthStatusCardTitleContainer__1ZjLx {
    margin-left: 0.75rem;
  }
}
.style_healthStatusCardTitleContainer__1ZjLx .style_healthStatusIconContainer__2okUn {
  display: flex;
  min-width: 1rem;
  padding-right: 0.4rem;
}
.style_healthStatusCardTitleContainer__1ZjLx .style_healthStatusName__2qWV4 {
  color: var(--primaryText);
  font-weight: 500;
  margin: 0.25rem 0;
  padding-right: 0.4rem;
}
.style_healthStatusCardTitleContainer__1ZjLx .style_healthStatusMachinesCount__3Rbm7 {
  color: var(--secondaryText);
  margin: 0.25rem 0;
  padding-right: 0.4rem;
}
.style_expandLink__27v7L {
  width: 6rem;
  height: 1.19rem;
  font-family: Roboto;
  font-size: 0.875rem;
  line-height: 1.36rem;
  color: var(--secondaryText);
  margin-right: 1.5rem;
}
.style_linkContainer__OiPUZ {
  width: 100%;
  text-align: right;
}
.style_hide__2wYwi {
  font-family: Roboto;
  font-size: 0.75rem;
  line-height: 1.83rem;
  text-align: left;
  color: var(--secondaryText);
  text-align: right;
}
.style_hide__2wYwi a {
  color: var(--mainText);
}
.style_environmentDescription__2nvdC {
  font-size: 0.875rem;
}
.style_environmentSelectorContainer__1llGK {
  width: 15.6rem !important;
  /* mark.siedle: This matches the sidebarContainer width-padding. Pls be careful about changing this. */
}
